import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstComparable,
  setSecondComparable,
  setThirdComparable,
} from "../../../../../redux/actions/global.action";
import globalService from "../../../../../services/global.service";
import { availableStates } from "../../../../../utils/constants";

const useLocationHook = () => {
  const dispatch = useDispatch();
  const firstComparableRef = useRef();
  const secondComparableRef = useRef();
  const thirdComparableRef = useRef();

  const {
    states,
    firstComparable,
    secondComparable,
    thirdComparable,
    stateInFocus,
    activeLgasOfFocusedState,
    lgInFocus,
  } = useSelector((state) => state.global);
  const { myData } = useSelector((state) => state.user);

  // useEffect(() => {
  //   const element = messageBodyRef.current;
  //   element.scrollTop = element.scrollHeight;
  // }, [messagesInView]);

  useEffect(() => {
    dispatch(
      setFirstComparable({
        ...firstComparable,
        states: states?.filter(
          (state) => availableStates.includes(state?.dh_state) && state
        ),
        ...(stateInFocus && {
          state: stateInFocus,
          lgas: activeLgasOfFocusedState?.map((lga) => ({
            ...lga,
            value: lga.dh_id,
            label: lga.dh_lga,
          })),
        }),
        ...(lgInFocus && {
          lga: lgInFocus,
        }),
      })
    );

    dispatch(
      setSecondComparable({
        ...secondComparable,
        states: states?.filter(
          (state) => availableStates.includes(state?.dh_state) && state
        ),
        ...(stateInFocus && {
          state: stateInFocus,
          lgas: activeLgasOfFocusedState?.map((lga) => ({
            ...lga,
            value: lga.dh_id,
            label: lga.dh_lga,
          })),
        }),
        ...(lgInFocus && {
          lga: lgInFocus,
        }),
      })
    );

    dispatch(
      setThirdComparable({
        ...thirdComparable,
        states: states?.filter(
          (state) => availableStates.includes(state?.dh_state) && state
        ),
        ...(stateInFocus && {
          state: stateInFocus,
          lgas: activeLgasOfFocusedState?.map((lga) => ({
            ...lga,
            value: lga.dh_id,
            label: lga.dh_lga,
          })),
        }),
        ...(lgInFocus && {
          lga: lgInFocus,
        }),
      })
    );
    if (lgInFocus) {
      globalService
        .getFacilitiesInLga(lgInFocus?.dh_id)
        .then(({ message }) => {
          dispatch(
            setFirstComparable({
              ...firstComparable,
              states: states?.filter(
                (state) => availableStates.includes(state?.dh_state) && state
              ),
              ...(stateInFocus && {
                state: stateInFocus,
                lgas: activeLgasOfFocusedState?.map((lga) => ({
                  ...lga,
                  value: lga.dh_id,
                  label: lga.dh_lga,
                })),
              }),
              lga: lgInFocus,
              facilities: message?.map((facility) => ({
                ...facility,
                value: facility.dh_id,
                label: facility.dh_facility,
              })),
            })
          );

          dispatch(
            setSecondComparable({
              ...secondComparable,
              states: states?.filter(
                (state) => availableStates.includes(state?.dh_state) && state
              ),
              ...(stateInFocus && {
                state: stateInFocus,
                lgas: activeLgasOfFocusedState?.map((lga) => ({
                  ...lga,
                  value: lga.dh_id,
                  label: lga.dh_lga,
                })),
              }),
              lga: lgInFocus,
              facilities: message?.map((facility) => ({
                ...facility,
                value: facility.dh_id,
                label: facility.dh_facility,
              })),
            })
          );

          dispatch(
            setThirdComparable({
              ...thirdComparable,
              states: states?.filter(
                (state) => availableStates.includes(state?.dh_state) && state
              ),
              ...(stateInFocus && {
                state: stateInFocus,
                lgas: activeLgasOfFocusedState?.map((lga) => ({
                  ...lga,
                  value: lga.dh_id,
                  label: lga.dh_lga,
                })),
              }),
              lga: lgInFocus,
              facilities: message?.map((facility) => ({
                ...facility,
                value: facility.dh_id,
                label: facility.dh_facility,
              })),
            })
          );
        })
        .catch((error) => console.log(error, "is the error"));
    }
  }, [myData]);

  const handleSelectState = (section, selected) => {
    switch (section) {
      case "first":
        dispatch(
          setFirstComparable({
            ...firstComparable,
            state: selected,
            ...(selected?.value !== firstComparable.state?.value && {
              selectedStates: [],
              lga: null,
              selectedLgas: [],
              facilities: [],
              selectedFacilities: [],
            }),
          })
        );
        break;
      case "second":
        dispatch(
          setSecondComparable({
            ...secondComparable,
            state: selected,
            ...(selected?.value !== secondComparable.state?.value && {
              selectedStates: [],
              lga: null,
              selectedLgas: [],
              facilities: [],
              selectedFacilities: [],
            }),
          })
        );
        break;
      case "third":
        dispatch(
          setThirdComparable({
            ...thirdComparable,
            state: selected,
            ...(selected?.value !== secondComparable.state?.value && {
              selectedStates: [],
              lga: null,
              selectedLgas: [],
              facilities: [],
              selectedFacilities: [],
            }),
          })
        );
        break;
      default:
        break;
    }
    selected &&
      globalService
        .getActiveLgasInState(selected?.dh_id)
        .then(({ message }) => {
          switch (section) {
            case "first":
              dispatch(
                setFirstComparable({
                  ...firstComparable,
                  state: selected,
                  lgas: message?.map((lga) => ({
                    ...lga,
                    value: lga.dh_id,
                    label: lga.dh_lga,
                  })),
                })
              );
              break;
            case "second":
              dispatch(
                setSecondComparable({
                  ...secondComparable,
                  state: selected,
                  lgas: message?.map((lga) => ({
                    ...lga,
                    value: lga.dh_id,
                    label: lga.dh_lga,
                  })),
                })
              );
              break;
            case "third":
              dispatch(
                setThirdComparable({
                  ...thirdComparable,
                  state: selected,
                  lgas: message?.map((lga) => ({
                    ...lga,
                    value: lga.dh_id,
                    label: lga.dh_lga,
                  })),
                })
              );
              break;
            default:
              break;
          }
        })
        .catch((error) => console.log(error, "is the error"));
  };

  const handleSelectLga = (section, selected) => {
    switch (section) {
      case "first":
        dispatch(
          setFirstComparable({
            ...firstComparable,
            lga: selected,
            ...(selected?.value !== firstComparable.lga?.value && {
              facilities: [],
              selectedFacilities: [],
            }),
          })
        );
        break;
      case "second":
        dispatch(
          setSecondComparable({
            ...secondComparable,
            lga: selected,
            ...(selected?.value !== secondComparable.lga?.value && {
              facilities: [],
              selectedFacilities: [],
            }),
          })
        );
        break;
      case "third":
        dispatch(
          setThirdComparable({
            ...thirdComparable,
            lga: selected,
            ...(selected?.value !== thirdComparable.lga?.value && {
              facilities: [],
              selectedFacilities: [],
            }),
          })
        );
        break;
      default:
        break;
    }
    selected &&
      globalService
        .getFacilitiesInLga(selected?.dh_id)
        .then(({ message }) => {
          switch (section) {
            case "first":
              dispatch(
                setFirstComparable({
                  ...firstComparable,
                  lga: selected,
                  facilities: message?.map((facility) => ({
                    ...facility,
                    value: facility.dh_id,
                    label: facility.dh_facility,
                  })),
                })
              );
              break;
            case "second":
              dispatch(
                setSecondComparable({
                  ...secondComparable,
                  lga: selected,
                  facilities: message?.map((facility) => ({
                    ...facility,
                    value: facility.dh_id,
                    label: facility.dh_facility,
                  })),
                })
              );
              break;
            case "third":
              dispatch(
                setThirdComparable({
                  ...thirdComparable,
                  lga: selected,
                  facilities: message?.map((facility) => ({
                    ...facility,
                    value: facility.dh_id,
                    label: facility.dh_facility,
                  })),
                })
              );
              break;
            default:
              break;
          }
        })
        .catch((error) => console.log(error, "is the error"));
  };

  const handleChangeAction = (section, selected) => {
    switch (section) {
      case "first":
        if (selected?.value === "lga_facilities") {
          setTimeout(() => {
            firstComparableRef.current.scrollTop =
              firstComparableRef.current.scrollHeight;
          }, 1000);
        }

        dispatch(
          setFirstComparable({
            ...firstComparable,

            ...(selected?.value === "select_lga" ||
            selected?.value === "state_summary"
              ? {
                  stateAction: selected,
                }
              : {
                  lgaAction: selected,
                }),
          })
        );
        break;
      case "second":
        if (selected?.value === "lga_facilities") {
          setTimeout(() => {
            secondComparableRef.current.scrollTop =
              secondComparableRef.current.scrollHeight;
          }, 1000);
        }
        dispatch(
          setSecondComparable({
            ...secondComparable,
            ...(selected?.value === "select_lga" ||
            selected?.value === "state_summary"
              ? {
                  stateAction: selected,
                }
              : {
                  lgaAction: selected,
                }),
          })
        );
        break;
      case "third":
        if (selected?.value === "lga_facilities") {
          setTimeout(() => {
            thirdComparableRef.current.scrollTop =
              thirdComparableRef.current.scrollHeight;
          }, 1000);
        }
        dispatch(
          setThirdComparable({
            ...thirdComparable,
            ...(selected?.value === "select_lga" ||
            selected?.value === "state_summary"
              ? {
                  stateAction: selected,
                }
              : {
                  lgaAction: selected,
                }),
          })
        );
        break;
      default:
        break;
    }
  };

  const handleSelectFacility = (section, value) => {
    switch (section) {
      case "first":
        if (
          !firstComparable.selectedFacilities
            .map((indicator) => indicator?.dh_id)
            .includes(value?.dh_id)
        ) {
          dispatch(
            setFirstComparable({
              ...firstComparable,
              selectedFacilities: [
                // ...firstComparable.selectedFacilities,
                value,
              ],
            })
          );
        } else {
          dispatch(
            setFirstComparable({
              ...firstComparable,
              selectedFacilities: firstComparable.selectedFacilities.filter(
                (facility) => facility?.dh_id != value?.dh_id
              ),
            })
          );
        }
        break;
      case "second":
        if (
          !secondComparable.selectedFacilities
            .map((indicator) => indicator?.dh_id)
            .includes(value?.dh_id)
        ) {
          dispatch(
            setSecondComparable({
              ...secondComparable,
              selectedFacilities: [
                // ...secondComparable.selectedFacilities,
                value,
              ],
            })
          );
        } else {
          dispatch(
            setSecondComparable({
              ...secondComparable,
              selectedFacilities: secondComparable.selectedFacilities.filter(
                (facility) => facility?.dh_id != value?.dh_id
              ),
            })
          );
        }
        break;
      case "third":
        if (
          !thirdComparable.selectedFacilities
            .map((indicator) => indicator?.dh_id)
            .includes(value?.dh_id)
        ) {
          dispatch(
            setThirdComparable({
              ...thirdComparable,
              selectedFacilities: [
                // ...thirdComparable.selectedFacilities,
                value,
              ],
            })
          );
        } else {
          dispatch(
            setThirdComparable({
              ...thirdComparable,
              selectedFacilities: thirdComparable.selectedFacilities.filter(
                (facility) => facility?.dh_id != value?.dh_id
              ),
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const handleCancelSection = (section) => {
    switch (section) {
      case "first":
        dispatch(
          setFirstComparable({
            ...firstComparable,
            shouldShow: false,
            state: null,
            states: [],
            selectedStates: [],
            lga: null,
            lgas: [],
            selectedLgas: [],
            facilities: [],
            selectedFacilities: [],
            stateAction: null,
            lgaAction: null,
            stateActions: [
              {
                label: "View State Summary",
                value: "state_summary",
              },
              {
                label: "Select LGA",
                value: "select_lga",
              },
            ],
            lgaActions: [
              {
                label: "View LGA Summary",
                value: "lga_summary",
              },
              {
                label: "View LGA Facilities",
                value: "lga_facilities",
              },
            ],
          })
        );
        break;
      case "second":
        dispatch(
          setSecondComparable({
            ...secondComparable,
            shouldShow: false,
            state: null,
            states: [],
            selectedStates: [],
            lga: null,
            lgas: [],
            selectedLgas: [],
            facilities: [],
            selectedFacilities: [],
            stateAction: null,
            lgaAction: null,
            stateActions: [
              {
                label: "View State Summary",
                value: "state_summary",
              },
              {
                label: "Select LGA",
                value: "select_lga",
              },
            ],
            lgaActions: [
              {
                label: "View LGA Summary",
                value: "lga_summary",
              },
              {
                label: "View LGA Facilities",
                value: "lga_facilities",
              },
            ],
          })
        );
        break;
      case "third":
        dispatch(
          setThirdComparable({
            ...thirdComparable,
            shouldShow: false,
            state: null,
            states: [],
            selectedStates: [],
            lga: null,
            lgas: [],
            selectedLgas: [],
            facilities: [],
            selectedFacilities: [],
            stateAction: null,
            lgaAction: null,
            stateActions: [
              {
                label: "View State Summary",
                value: "state_summary",
              },
              {
                label: "Select LGA",
                value: "select_lga",
              },
            ],
            lgaActions: [
              {
                label: "View LGA Summary",
                value: "lga_summary",
              },
              {
                label: "View LGA Facilities",
                value: "lga_facilities",
              },
            ],
          })
        );
        break;
      default:
        break;
    }
  };

  return {
    firstComparable,
    secondComparable,
    thirdComparable,
    myData,
    states,
    handleSelectState,
    handleSelectLga,
    handleChangeAction,
    handleSelectFacility,
    handleCancelSection,
    dispatch,
    firstComparableRef,
    secondComparableRef,
    thirdComparableRef,
    stateInFocus,
    lgInFocus,
  };
};

export default useLocationHook;
