import React from "react";
import { Link } from "react-router-dom";
import "./Error404Page.css";

const Error404Page = () => {
  return (
    <div className="error-page-root-container">
      <div className="error-title">404</div>
      <p className="error-sub-title">Page not found</p>
      <Link className="back-home-button" to="/">
        Back to Home
      </Link>
    </div>
  );
};

export default Error404Page;
