import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Home.css";
import {
  setActiveLgasOfFocusedState,
  setActiveStates,
  setFirstComparable,
  setLgasInState,
  setLgInFocus,
  setPopupChildren,
  setSelectedLevel,
  setStateInFocus,
  setStates,
  togglePopup,
} from "../../redux/actions/global.action";
import { Facebook, Instagram, Twitter, YouTube } from "@mui/icons-material";

import { ReactComponent as Map } from "../../assets/images/Map.svg";
import { useDispatch, useSelector } from "react-redux";
import { FeedbackModal } from "./components";
import Nigeria from "@svg-maps/nigeria";
import { CheckboxSVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import globalService from "../../services/global.service";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { availableStates } from "../../utils/constants";
import { dontShowIntro, formatArrayToString } from "../../utils/helpers";
import FirstTimeInfo from "../../components/FirstTimeInfo";

const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gettingLgas, setGettingLgas] = useState(false);
  const {
    states,
    activeStates,
    firstComparable,
    activeLgasOfFocusedState,
    stateInFocus,
  } = useSelector((state) => state.global);
  const { myData } = useSelector((state) => state.user);
  const noShowIntro = dontShowIntro();
  const location = useLocation();
  useEffect(() => {
    if (!noShowIntro && location.pathname == "/") {
      dispatch(togglePopup(true));
      dispatch(setPopupChildren(<FirstTimeInfo />));
    }

    return () => dispatch(togglePopup(false));
  }, []);
  useEffect(() => {
    if (myData) {
      if (!myData?.roles?.includes("VacNationalAdmin")) {
        const element = document.querySelector(
          `[name="${stateInFocus?.dh_state}"]`
        );

        element?.setAttribute("aria-checked", "true");
      } else if (activeStates?.length) {
        activeStates?.forEach((state) => {
          const element = document.querySelector(`[name="${state?.dh_state}"]`);

          element?.setAttribute("aria-checked", "true");
        });
      }
    }
  }, [activeStates, stateInFocus, myData]);

  const customLocationProps = {
    ...Nigeria,
    locations: Nigeria.locations.map((location, i) => {
      return location;
    }),
  };

  const getStates = () => {
    globalService
      .getStates()
      .then((data) => {
        if (data.message) {
          dispatch(
            setStates(
              data.message?.map((state) => ({
                ...state,
                value: state.dh_id,
                label: state.dh_state,
              }))
            )
          );
          data?.message.forEach((state) => {
            const pathElement = document.querySelector(
              `${state.dh_state?.toLowerCase()}`
            );

            if (pathElement) {
              pathElement.setAttribute("checked", "true");
            }
          });
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getActiveStates = () => {
    globalService
      .getActiveStates()
      .then((data) => {
        if (data.message) {
          dispatch(
            setActiveStates(
              data.message?.map((state) => ({
                ...state,
                value: state.dh_id,
                label: state.dh_state,
              }))
            )
          );

          if (
            myData?.roles?.includes("VacNationalAdmin") ||
            myData.email == "national.vacinsight@sydani.org"
          ) {
            dispatch(setSelectedLevel("national"));
          }
          if (myData?.roles?.includes("VacStateAdmin")) {
            const state = myData?.roles.find((item) =>
              data.message?.some((obj) => obj.dh_state === item)
            );
            const stateObj = data.message?.filter(
              (obj) => obj.dh_state == state
            )[0];
            dispatch(setSelectedLevel("state"));
            dispatch(
              setStateInFocus({
                ...stateObj,
                value: stateObj.dh_id,
                label: stateObj.dh_state,
              })
            );
            getLgasOfFocusedState(stateObj.dh_id);
          }
          if (myData?.roles?.includes("VacLgaAdmin")) {
            const state = myData?.roles.find((item) =>
              data.message?.some((obj) => obj.dh_state === item)
            );
            const stateObj = data.message?.filter(
              (obj) => obj.dh_state == state
            )[0];
            dispatch(setSelectedLevel("lga"));
            dispatch(
              setStateInFocus({
                ...stateObj,
                value: stateObj.dh_id,
                label: stateObj.dh_state,
              })
            );
            getLgasOfFocusedState(stateObj.dh_id);
          }
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getLgasInState = (e) => {
    const state = e.target.getAttribute("name");

    if (states.length) {
      if (!myData?.roles?.includes("VacNationalAdmin")) {
        const foundState = myData?.roles?.some((item) => state === item);
        if (!foundState) {
          e.target.blur();
          e.target.setAttribute("aria-checked", "false");
          return toast.info("This region is not available to you");
        }
        const selected = states?.filter((s) => s?.dh_state == state)[0];
        dispatch(
          setStateInFocus({
            ...selected,
            value: selected.dh_id,
            label: selected.dh_state,
          })
        );
        if (
          selected &&
          activeStates
            ?.map((state) => state?.dh_state)
            .includes(selected?.dh_state)
        ) {
          setGettingLgas(true);
          globalService
            .getActiveLgasInState(selected?.dh_id)
            .then((data) => {
              dispatch(
                setLgasInState(
                  [{ value: "all", label: "All" }].concat(
                    data.message?.map((lg) => ({
                      ...lg,
                      label: lg?.dh_lga,
                      value: lg?.dh_id,
                    }))
                  )
                )
              );
              // getLgasOfFocusedState(selected?.dh_id);
              // dispatch(setStateInFocus(state));

              dispatch(
                setFirstComparable({
                  ...firstComparable,
                  state: selected,
                  lgas: data.message?.map((lg) => ({
                    ...lg,
                    label: lg?.dh_lga,
                    value: lg?.dh_id,
                  })),
                  ...(selected?.value !== firstComparable.state?.value && {
                    selectedStates: [],
                    lga: null,
                    selectedLgas: [],
                    facilities: [],
                    selectedFacilities: [],
                  }),
                })
              );
              navigate("/program_areas");
            })
            .catch((err) => console.log(err))
            .finally(() => setGettingLgas(false));
        }
      }
      const selected = states?.filter((s) => s?.dh_state == state)[0];

      if (
        selected &&
        activeStates
          ?.map((state) => state?.dh_state)
          .includes(selected?.dh_state)
      ) {
        setGettingLgas(true);
        globalService
          .getActiveLgasInState(selected?.dh_id)
          .then((data) => {
            dispatch(
              setLgasInState(
                [{ value: "all", label: "All" }].concat(
                  data.message?.map((lg) => ({
                    ...lg,
                    label: lg?.dh_lga,
                    value: lg?.dh_id,
                  }))
                )
              )
            );
            // getLgasOfFocusedState(selected?.dh_id);
            // dispatch(setStateInFocus(state));

            dispatch(
              setFirstComparable({
                ...firstComparable,
                state: selected,
                lgas: data.message?.map((lg) => ({
                  ...lg,
                  label: lg?.dh_lga,
                  value: lg?.dh_id,
                })),
                ...(selected?.value !== firstComparable.state?.value && {
                  selectedStates: [],
                  lga: null,
                  selectedLgas: [],
                  facilities: [],
                  selectedFacilities: [],
                }),
              })
            );
            navigate("/program_areas");
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingLgas(false));
      } else {
        e.target.blur();
        e.target.setAttribute("aria-checked", "false");
        toast.error("This region is currently not available");
      }
    } else {
      e.target.blur();
      e.target.setAttribute("aria-checked", "false");
      toast.error("Unable to proceed. Please try again");
      getStates();
      getActiveStates();
    }
  };

  const getLgasOfFocusedState = (stateId) => {
    globalService
      .getActiveLgasInState(stateId)
      .then((data) => {
        dispatch(
          setActiveLgasOfFocusedState(
            data.message?.map((lg) => ({
              ...lg,
              label: lg?.dh_lga,
              value: lg?.dh_id,
            }))
          )
        );
        if (myData?.roles?.includes("VacLgaAdmin")) {
          const lga = myData?.roles.find((item) =>
            data.message?.some((obj) => obj.dh_lga === item)
          );
          const lgaObj = data.message?.filter((obj) => obj.dh_lga == lga)[0];
          dispatch(setSelectedLevel("lga"));
          dispatch(
            setLgInFocus({
              ...lgaObj,
              value: lgaObj.dh_id,
              label: lgaObj.dh_lga,
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setGettingLgas(false));
  };

  return (
    <div className={"home-root"}>
      <div className={"home-left"}>
        {gettingLgas && (
          <div className="map-loader">
            <RotatingLines
              strokeColor="#59b952"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        )}
        <Map
          style={{ position: "absolute" }}
          onClick={() => navigate("/program_areas")}
          width={"100%"}
        />
        <CheckboxSVGMap
          className="map-outer"
          locationClassName="location-area"
          map={customLocationProps}
          // onLocationClick={(e) => console.log(e)}
          onLocationFocus={(e) => getLgasInState(e)}
        />
        <div className={"map-select-container"}>
          <p className={"map-select-text"}>
            {`Select your preferred state to get started. (Data loaded for ${formatArrayToString(
              activeStates?.map((state) => state.label)
            )} States currently)`}
          </p>
        </div>
      </div>
      <div className={"home-right"}>
        <div>
          <p className={"welcome-text"}>Welcome to VacInsights</p>
        </div>
        <div>
          <p className={"description"}>
            VacInsight Platform, is developed by the Vaccine Data CoLab in
            partnership with Sydani Group to transform routine immunization
            decision-making in Nigeria.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "25vh",
          }}
        >
          <Link className={"about-btn"} to="/about">
            {" "}
            Learn More{" "}
          </Link>
        </div>

        {/* <div>
          <div
            style={{
              marginBottom: -32,
              marginTop: 50,
              width: 350,
            }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: 13,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              LATEST NEWS
            </p>
          </div>
          <Carousel
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={true}
            NextIcon={<KeyboardArrowRight style={{ color: "#000" }} />}
            PrevIcon={<KeyboardArrowLeft style={{ color: "#000" }} />}
            navButtonsProps={{
              // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
              style: {
                backgroundColor: "#fff",
                borderRadius: 2,
                height: "15px",
                width: "30px",
              },
            }}
            navButtonsWrapperProps={{
              // Move the buttons to the bottom. Unsetting top here to override default style.
              style: {
                position: "absolute",
                height: "15px",
                width: "30px",
                backgroundColor: "transparent",
                top: 20,
                right: 20,
                "&:hover": {
                  "& $button": {
                    backgroundColor: "black",
                    filter: "brightness(300%)",
                    opacity: "1",
                  },
                },
              },
            }}
            className={"carousel"}
          >
            {newsDatas.map((item, i) => (
              <div className={"news-item-container"}>
                <div className={"news-itemInner-container"}>
                  <div className={"img-container"} key={item.id}>
                    <img src={item.image} className={"carousel-img"} />
                  </div>
                  <p className={"news-description"}>{item.description}</p>
                </div>
              </div>
            ))}
          </Carousel>
        </div> */}
        <div className={"left-bottom"}>
          <a
            // onClick={() => {
            //   dispatch(setPopupChildren(<FeedbackModal />));
            //   dispatch(togglePopup(true));
            // }}
            href={"https://ee.kobotoolbox.org/x/Oy9HCN8o"}
            target="__blank"
            style={{
              textDecorationLine: "underline",
              fontSize: 15,
              color: "#fff",
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            Share your feedback
          </a>
          <div className={"social-connect-container"}>
            <p style={{ fontSize: 15, color: "#fff", textAlign: "left" }}>
              Connect with Us
            </p>
            <IconButton className={"soc-icon-btn"}>
              <Instagram className={"soc-icon"} />
            </IconButton>
            <IconButton className={"soc-icon-btn"}>
              <Facebook className={"soc-icon"} />
            </IconButton>
            <IconButton className={"soc-icon-btn"}>
              <Twitter className={"soc-icon"} />
            </IconButton>
            <IconButton className={"soc-icon-btn"}>
              <YouTube className={"soc-icon"} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
