import React, { useState } from "react";
import "./Summary.css";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  Tooltip,
  Legend,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global.action";
import FeedbackModal from "../../../Home/components/FeedbackModal/FeedbackModal";
import { useNavigate } from "react-router-dom";
import { IconButton, Skeleton } from "@mui/material";
import { Download } from "@mui/icons-material";
import ShowMore from "./components/ShowMore";
import { colors } from "../../../../utils/constants";
import Insight from "./components/Insight";
import Recommendation from "./components/Recommendation";
import { getRiStateChartData } from "../../../../utils/helpers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SummaryPdf from "./SummaryPdf";
import DownloadModal from "./components/DownloadModal";
import { Margin, usePDF } from "react-to-pdf";

const Summary = () => {
  const {
    selectedStates,
    selectedLgas,
    selectedFacilities,
    gettingChartData,
    chart,
    firstComparable,
    secondComparable,
    thirdComparable,
    selectedElements,
  } = useSelector((state) => state.global);

  const emptyCharts = new Array(3);
  const [activeTab, setActiveTab] = useState("insight");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toPDF, targetRef } = usePDF({
    filename:
      "vac-insight-chart-" +
      new Date().toISOString().replace(/:/g, "-") +
      ".pdf",
    page: { margin: Margin.MEDIUM },
  });
  const onClickDownload = () => {
    dispatch(togglePopup(true));
    dispatch(
      setPopupChildren(
        <DownloadModal
          gettingChartData={gettingChartData}
          firstComparable={firstComparable}
          secondComparable={secondComparable}
          thirdComparable={thirdComparable}
          selectedElements={selectedElements}
          toPdf={toPDF}
        />
      )
    );
  };
  return (
    <div className="summary-root-container">
      <div className="summary-left" id="chart-area" ref={targetRef}>
        {/* {emptyCharts.fill(null).map((chart, index) => (
          <div className="graph-container-box">
            <div className="graph-main-inner-container">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  margin={{ top: 0, right: 0, bottom: 5, left: 0 }}
                  data={data}
                >
                  <CartesianGrid strokeDasharray="0 1 0" />
                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey="year"
                    fontSize={10}
                    height={10}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    fontSize={10}
                    width={30}
                    hide={true}
                  />
                
                  <Bar dataKey="given" fill="#152d6e" />
                  <Bar dataKey="target" fill="#ffc60c" />
                  <Bar dataKey="pending" fill="#ffc4c4" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="graph-info-container">
              <div className="graph-info-row">
                <div className="indicator-one-bullet" />
                <p className="indicator-text">
                  Number of vaccines given disaggregated by antigen, LGA and
                  period
                </p>
              </div>
              <div className="graph-info-row">
                <div className="indicator-two-bullet" />
                <p className="indicator-text">
                  Proportion of target population vaccinated
                </p>
              </div>
              <div className="graph-info-row">
                <div className="indicator-three-bullet" />
                <p className="indicator-text">
                  Proportion of population yet to get vaccinated
                </p>
              </div>
            </div>
          </div>
        ))} */}

        {gettingChartData ? (
          emptyCharts.fill(null).map((chart, index) => (
            <div
              style={{
                width: "95%",
              }}
            >
              <Skeleton
                style={{
                  width: "100%",
                  margin: "-100px auto",
                }}
                height={500}
              />
            </div>
          ))
        ) : firstComparable?.stateCategoryResult?.length ? (
          firstComparable?.stateCategoryResult
            ?.filter((item) => {
              if (selectedElements?.length) {
                if (selectedElements?.includes(item?.element)) {
                  return item;
                }
              } else {
                return item;
              }
            })
            ?.map(
              (
                {
                  element,
                  state,
                  lga,
                  facility,
                  total_stock,
                  total_opening_balance,
                  total_received,
                  total_consumption,
                  total_coverage,
                  total_stockout,
                  total_Penta_1,
                  total_Penta_2,
                  total_Penta_3,
                  total_wastage,
                },
                index
              ) => (
                <div className="graph-container-box">
                  <div className="graph-main-inner-container">
                    <div
                      style={{
                        fontWeight: 500,
                        color: "#011b60",
                        margin: 5,
                        fontSize: 13,
                      }}
                    >
                      {facility || lga || state}
                    </div>
                    <div
                      style={{ fontWeight: 600, color: "#011b60", margin: 5 }}
                    >
                      {element}
                    </div>

                    <ResponsiveContainer width={400} height="80%">
                      <BarChart
                        margin={{ top: 0, right: 0, bottom: 5, left: 10 }}
                        data={getRiStateChartData({
                          total_stock,
                          total_opening_balance,
                          total_received,
                          total_consumption,
                          total_coverage,
                          total_stockout,
                          total_Penta_1,
                          total_Penta_2,
                          total_Penta_3,
                          total_wastage,
                        })}
                      >
                        <CartesianGrid strokeDasharray="0 1 0" />
                        <Tooltip />

                        <XAxis
                          axisLine={false}
                          tickLine={false}
                          dataKey="period"
                          fontSize={10}
                          height={10}
                        />
                        <YAxis
                          axisLine={false}
                          tickLine={false}
                          fontSize={10}
                          width={30}
                        />

                        {total_stock && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_stock"
                            fill={colors[0]}
                          />
                        )}
                        {total_opening_balance && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_opening_balance"
                            fill={colors[1]}
                          />
                        )}
                        {total_received && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_received"
                            fill={colors[2]}
                          />
                        )}
                        {total_consumption && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_consumption"
                            fill={colors[3]}
                          />
                        )}
                        {total_coverage && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_coverage"
                            fill={colors[0]}
                          />
                        )}
                        {total_stockout && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_stockout"
                            fill={colors[1]}
                          />
                        )}
                        {total_Penta_1 && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_Penta_1"
                            fill={colors[0]}
                          />
                        )}
                        {total_Penta_2 && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_Penta_2"
                            fill={colors[1]}
                          />
                        )}
                        {total_Penta_3 && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_Penta_3"
                            fill={colors[2]}
                          />
                        )}
                        {total_wastage && (
                          <Bar
                            maxBarSize={20}
                            dataKey="total_wastage"
                            fill={colors[3]}
                          />
                        )}
                        {/* <Bar dataKey="target" fill="#ffc60c" />
              <Bar dataKey="pending" fill="#ffc4c4" /> */}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className="graph-info-container">
                    {total_stock && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[0] }}
                        />
                        <p className="indicator-text">Total stock</p>
                      </div>
                    )}
                    {total_opening_balance && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[1] }}
                        />
                        <p className="indicator-text">Total opening balance</p>
                      </div>
                    )}
                    {total_received && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[2] }}
                        />
                        <p className="indicator-text">Total received</p>
                      </div>
                    )}
                    {total_consumption && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[3] }}
                        />
                        <p className="indicator-text">Total consumption</p>
                      </div>
                    )}
                    {total_coverage && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[0] }}
                        />
                        <p className="indicator-text">Total coverage</p>
                      </div>
                    )}
                    {total_stockout && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[1] }}
                        />
                        <p className="indicator-text">Total Stockout</p>
                      </div>
                    )}
                    {total_Penta_1 && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[0] }}
                        />
                        <p className="indicator-text">Total Penta 1</p>
                      </div>
                    )}
                    {total_Penta_2 && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[1] }}
                        />
                        <p className="indicator-text">Total Penta 2</p>
                      </div>
                    )}
                    {total_Penta_3 && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[2] }}
                        />
                        <p className="indicator-text">Total Penta 3</p>
                      </div>
                    )}
                    {total_wastage && (
                      <div className="graph-info-row">
                        <div
                          className="indicator-bullet"
                          style={{ backgroundColor: colors[3] }}
                        />
                        <p className="indicator-text">Total wastage</p>
                      </div>
                    )}
                    {/* <div className="graph-info-row">
                  <div className="indicator-two-bullet" />
                  <p className="indicator-text">
                    Proportion of target population vaccinated
                  </p>
                </div>
                <div className="graph-info-row">
                  <div className="indicator-three-bullet" />
                  <p className="indicator-text">
                    Proportion of population yet to get vaccinated
                  </p>
                </div> */}
                  </div>
                </div>
              )
            )
        ) : (
          <div style={{ color: "#011b60", marginTop: 100 }}>
            No record found
          </div>
        )}
        {secondComparable?.stateCategoryResult?.length &&
        secondComparable?.shouldShow
          ? secondComparable?.stateCategoryResult
              ?.filter((item) => {
                if (selectedElements?.length) {
                  if (selectedElements?.includes(item?.element)) {
                    return item;
                  }
                } else {
                  return item;
                }
              })
              ?.map(
                (
                  {
                    element,
                    state,
                    lga,
                    facility,
                    total_stock,
                    total_opening_balance,
                    total_received,
                    total_consumption,
                    total_coverage,
                    total_stockout,
                    total_Penta_1,
                    total_Penta_2,
                    total_Penta_3,
                    total_wastage,
                  },
                  index
                ) => (
                  <div className="graph-container-box">
                    <div className="graph-main-inner-container">
                      <div
                        style={{
                          fontWeight: 500,
                          color: "#011b60",
                          margin: 5,
                          fontSize: 13,
                        }}
                      >
                        {facility || lga || state}
                      </div>
                      <div
                        style={{ fontWeight: 600, color: "#011b60", margin: 5 }}
                      >
                        {element}
                      </div>

                      <ResponsiveContainer width={400} height="80%">
                        <BarChart
                          margin={{ top: 0, right: 0, bottom: 5, left: 10 }}
                          data={getRiStateChartData({
                            total_stock,
                            total_opening_balance,
                            total_received,
                            total_consumption,
                            total_coverage,
                            total_stockout,
                            total_Penta_1,
                            total_Penta_2,
                            total_Penta_3,
                            total_wastage,
                          })}
                        >
                          <CartesianGrid strokeDasharray="0 1 0" />
                          <Tooltip />

                          <XAxis
                            axisLine={false}
                            tickLine={false}
                            dataKey="period"
                            fontSize={10}
                            height={10}
                          />
                          <YAxis
                            axisLine={false}
                            tickLine={false}
                            fontSize={10}
                            width={30}
                          />

                          {total_stock && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_stock"
                              fill={colors[0]}
                            />
                          )}
                          {total_opening_balance && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_opening_balance"
                              fill={colors[1]}
                            />
                          )}
                          {total_received && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_received"
                              fill={colors[2]}
                            />
                          )}
                          {total_consumption && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_consumption"
                              fill={colors[3]}
                            />
                          )}
                          {total_coverage && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_coverage"
                              fill={colors[0]}
                            />
                          )}
                          {total_stockout && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_stockout"
                              fill={colors[1]}
                            />
                          )}
                          {total_Penta_1 && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_Penta_1"
                              fill={colors[0]}
                            />
                          )}
                          {total_Penta_2 && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_Penta_2"
                              fill={colors[1]}
                            />
                          )}
                          {total_Penta_3 && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_Penta_3"
                              fill={colors[2]}
                            />
                          )}
                          {total_wastage && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_wastage"
                              fill={colors[3]}
                            />
                          )}
                          {/* <Bar dataKey="target" fill="#ffc60c" />
              <Bar dataKey="pending" fill="#ffc4c4" /> */}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="graph-info-container">
                      {total_stock && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[0] }}
                          />
                          <p className="indicator-text">Total stock</p>
                        </div>
                      )}
                      {total_opening_balance && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[1] }}
                          />
                          <p className="indicator-text">
                            Total opening balance
                          </p>
                        </div>
                      )}
                      {total_received && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[2] }}
                          />
                          <p className="indicator-text">Total received</p>
                        </div>
                      )}
                      {total_consumption && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[3] }}
                          />
                          <p className="indicator-text">Total consumption</p>
                        </div>
                      )}
                      {total_coverage && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[0] }}
                          />
                          <p className="indicator-text">Total coverage</p>
                        </div>
                      )}
                      {total_stockout && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[1] }}
                          />
                          <p className="indicator-text">Total Stockout</p>
                        </div>
                      )}
                      {total_Penta_1 && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[0] }}
                          />
                          <p className="indicator-text">Total Penta 1</p>
                        </div>
                      )}
                      {total_Penta_2 && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[1] }}
                          />
                          <p className="indicator-text">Total Penta 2</p>
                        </div>
                      )}
                      {total_Penta_3 && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[2] }}
                          />
                          <p className="indicator-text">Total Penta 3</p>
                        </div>
                      )}
                      {total_wastage && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[3] }}
                          />
                          <p className="indicator-text">Total wastage</p>
                        </div>
                      )}
                      {/* <div className="graph-info-row">
                  <div className="indicator-two-bullet" />
                  <p className="indicator-text">
                    Proportion of target population vaccinated
                  </p>
                </div>
                <div className="graph-info-row">
                  <div className="indicator-three-bullet" />
                  <p className="indicator-text">
                    Proportion of population yet to get vaccinated
                  </p>
                </div> */}
                    </div>
                  </div>
                )
              )
          : null}
        {thirdComparable?.stateCategoryResult?.length &&
        thirdComparable?.shouldShow
          ? thirdComparable?.stateCategoryResult
              ?.filter((item) => {
                if (selectedElements?.length) {
                  if (selectedElements?.includes(item?.element)) {
                    return item;
                  }
                } else {
                  return item;
                }
              })
              ?.map(
                (
                  {
                    element,
                    state,
                    lga,
                    facility,
                    total_stock,
                    total_opening_balance,
                    total_received,
                    total_consumption,
                    total_coverage,
                    total_stockout,
                    total_Penta_1,
                    total_Penta_2,
                    total_Penta_3,
                    total_wastage,
                  },
                  index
                ) => (
                  <div className="graph-container-box">
                    <div className="graph-main-inner-container">
                      <div
                        style={{
                          fontWeight: 500,
                          color: "#011b60",
                          margin: 5,
                          fontSize: 13,
                        }}
                      >
                        {facility || lga || state}
                      </div>
                      <div
                        style={{ fontWeight: 600, color: "#011b60", margin: 5 }}
                      >
                        {element}
                      </div>

                      <ResponsiveContainer width={400} height="80%">
                        <BarChart
                          margin={{ top: 0, right: 0, bottom: 5, left: 10 }}
                          data={getRiStateChartData({
                            total_stock,
                            total_opening_balance,
                            total_received,
                            total_consumption,
                            total_coverage,
                            total_stockout,
                            total_Penta_1,
                            total_Penta_2,
                            total_Penta_3,
                            total_wastage,
                          })}
                        >
                          <CartesianGrid strokeDasharray="0 1 0" />
                          <Tooltip />

                          <XAxis
                            axisLine={false}
                            tickLine={false}
                            dataKey="period"
                            fontSize={10}
                            height={10}
                          />
                          <YAxis
                            axisLine={false}
                            tickLine={false}
                            fontSize={10}
                            width={30}
                          />

                          {total_stock && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_stock"
                              fill={colors[0]}
                            />
                          )}
                          {total_opening_balance && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_opening_balance"
                              fill={colors[1]}
                            />
                          )}
                          {total_received && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_received"
                              fill={colors[2]}
                            />
                          )}
                          {total_consumption && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_consumption"
                              fill={colors[3]}
                            />
                          )}
                          {total_coverage && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_coverage"
                              fill={colors[0]}
                            />
                          )}
                          {total_stockout && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_stockout"
                              fill={colors[1]}
                            />
                          )}
                          {total_Penta_1 && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_Penta_1"
                              fill={colors[0]}
                            />
                          )}
                          {total_Penta_2 && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_Penta_2"
                              fill={colors[1]}
                            />
                          )}
                          {total_Penta_3 && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_Penta_3"
                              fill={colors[2]}
                            />
                          )}
                          {total_wastage && (
                            <Bar
                              maxBarSize={20}
                              dataKey="total_wastage"
                              fill={colors[3]}
                            />
                          )}
                          {/* <Bar dataKey="target" fill="#ffc60c" />
              <Bar dataKey="pending" fill="#ffc4c4" /> */}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="graph-info-container">
                      {total_stock && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[0] }}
                          />
                          <p className="indicator-text">Total stock</p>
                        </div>
                      )}
                      {total_opening_balance && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[1] }}
                          />
                          <p className="indicator-text">
                            Total opening balance
                          </p>
                        </div>
                      )}
                      {total_received && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[2] }}
                          />
                          <p className="indicator-text">Total received</p>
                        </div>
                      )}
                      {total_consumption && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[3] }}
                          />
                          <p className="indicator-text">Total consumption</p>
                        </div>
                      )}
                      {total_coverage && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[0] }}
                          />
                          <p className="indicator-text">Total coverage</p>
                        </div>
                      )}
                      {total_stockout && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[1] }}
                          />
                          <p className="indicator-text">Total Stockout</p>
                        </div>
                      )}
                      {total_Penta_1 && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[0] }}
                          />
                          <p className="indicator-text">Total Penta 1</p>
                        </div>
                      )}
                      {total_Penta_2 && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[1] }}
                          />
                          <p className="indicator-text">Total Penta 2</p>
                        </div>
                      )}
                      {total_Penta_3 && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[2] }}
                          />
                          <p className="indicator-text">Total Penta 3</p>
                        </div>
                      )}
                      {total_wastage && (
                        <div className="graph-info-row">
                          <div
                            className="indicator-bullet"
                            style={{ backgroundColor: colors[3] }}
                          />
                          <p className="indicator-text">Total wastage</p>
                        </div>
                      )}
                      {/* <div className="graph-info-row">
                  <div className="indicator-two-bullet" />
                  <p className="indicator-text">
                    Proportion of target population vaccinated
                  </p>
                </div>
                <div className="graph-info-row">
                  <div className="indicator-three-bullet" />
                  <p className="indicator-text">
                    Proportion of population yet to get vaccinated
                  </p>
                </div> */}
                    </div>
                  </div>
                )
              )
          : null}
      </div>
      <div className="summary-right">
        <div className="tab-switch-container">
          <button
            className={
              activeTab == "insight" ? "active-tab-button" : "tab-button"
            }
            onClick={() => setActiveTab("insight")}
          >
            Insight
          </button>
          <button
            className={
              activeTab == "recommendation" ? "active-tab-button" : "tab-button"
            }
            onClick={() => setActiveTab("recommendation")}
          >
            Recommendation
          </button>
        </div>
        <div className="contents-section-container">
          {/* <ShowMore text={insightRecommendationText} maxCharCount={500} /> */}
          {activeTab == "recommendation" ? <Recommendation /> : <Insight />}
        </div>
        <div className="bottom-container">
          <IconButton className="export-button" onClick={onClickDownload}>
            {/* <PDFDownloadLink
              document={
                <InsightPdf
                  gettingChartData={gettingChartData}
                  firstComparable={firstComparable}
                  secondComparable={secondComparable}
                  thirdComparable={thirdComparable}
                  selectedElements={selectedElements}
                />
              }
              fileName="hhh.pdf"
            > */}
            <Download style={{ width: 20, height: 20, color: "#011b60" }} />
            {/* </PDFDownloadLink> */}
          </IconButton>

          <a
            href={"https://ee.kobotoolbox.org/x/Oy9HCN8o"}
            target="__blank"
            // onClick={() => {
            //   dispatch(setPopupChildren(<FeedbackModal />));
            //   dispatch(togglePopup(true));
            // }}
            className="share-feedback-button"
          >
            Share feedback
          </a>
          <button
            onClick={() => navigate("/")}
            className="back-dashboard-button"
          >
            Back to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default Summary;
