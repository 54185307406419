import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import Means from "../../../../../../assets/fonts/Means/Means-Regular.ttf";
import Avenir from "../../../../../../assets/fonts/Avenir/Avenir-Medium.ttf";
const InsightRecommendationPdf = ({
  firstComparable,
  secondComparable,
  thirdComparable,
  selectedElements,
}) => {
  Font.register({
    family: "Means",
    fonts: [
      {
        src: Means,
      },
    ],
  });
  Font.register({
    family: "Avenir",
    fonts: [
      {
        src: Avenir,
      },
    ],
  });
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      fontSize: "11px",
    },
    mainCategoryTitle: {
      fontWeight: 600,
      fontSize: "22px",
      textDeecoration: "underline",
      fontFamily: "Avenir",
    },
    title: {
      fontWeight: 600,
      fontSize: "18px",
      textDeecoration: "underline",
      fontFamily: "Avenir",
    },
    vacInsightLogoTitle: {
      fontWeight: 900,
      fontSize: "22px",
      fontFamily: "Means",
      marginRight: 10,
    },
    headerLeftContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    insightRecommendationRoot: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    recommendationRoot: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      marginTop: "20px",
    },
    insightRecommendationInner: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      fontFamily: "Avenir",
    },
    elementTitleRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
      flexDirection: "row",
    },
    elementText: {
      fontWeight: 500,
    },
    elementValueText: {
      fontWeight: 700,
      marginLeft: "3px",
      fontSize: "13px",
    },
    locationDurationTitle: {
      fontWeight: 800,
      fontSize: "14px",
      marginBottom: "8px",
    },
    bulletPoint: {
      marginBottom: "5px",
    },
    headerRowContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
  return (
    <Document>
      <Page size="A4" style={styles.section}>
        <View style={styles.insightRecommendationRoot}>
          {firstComparable?.stateCategory &&
          firstComparable?.stateCategoryResult?.length ? (
            <>
              <View style={styles.headerRowContainer}>
                <View style={styles.headerLeftContainer}>
                  <Text style={styles.mainCategoryTitle}>
                    {firstComparable?.stateCategory?.name}
                  </Text>

                  <Text style={styles.title}>Insight</Text>
                </View>
                <View>
                  <Text style={styles.vacInsightLogoTitle}>VacInsights</Text>
                </View>
              </View>

              <View style={styles.insightRecommendationInner}>
                {firstComparable?.stateCategoryResult
                  ?.filter((item) => {
                    if (selectedElements?.length) {
                      if (selectedElements?.includes(item?.element)) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  ?.map((item) =>
                    Object?.values(item?.insights)?.length ? (
                      <View>
                        {Object?.keys(item?.insights).map((key) => ({
                          key,
                          value: item?.insights[key],
                        }))[0]?.value?.length ? (
                          <>
                            <View style={styles.elementTitleRow}>
                              <Text style={styles.elementText}>Element: </Text>
                              <Text style={styles.elementValueText}>
                                {" "}
                                {item?.element}
                              </Text>
                            </View>
                            <Text style={styles.locationDurationTitle}>
                              {item?.facility || item?.lga || item?.state}
                            </Text>
                          </>
                        ) : null}

                        <View className="insight-recommendation-content">
                          <View>
                            {Object?.keys(item?.insights)
                              .map((key) => ({
                                key,
                                value: item?.insights[key],
                              }))[0]
                              .value?.map((insight) => (
                                <Text style={styles.bulletPoint}>
                                  • {insight}
                                </Text>
                              ))}
                          </View>
                        </View>
                      </View>
                    ) : null
                  )}
              </View>
            </>
          ) : null}

          {secondComparable?.stateCategory &&
          secondComparable?.stateCategoryResult?.length &&
          secondComparable?.shouldShow ? (
            <>
              {/* <View className="main-category-title">
            {secondComparable?.stateCategory?.name}
          </View> */}
              <View style={styles.insightRecommendationInner}>
                {secondComparable?.stateCategoryResult
                  ?.filter((item) => {
                    if (selectedElements?.length) {
                      if (selectedElements?.includes(item?.element)) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  ?.map((item) =>
                    Object?.values(item?.insights)?.length ? (
                      <View>
                        {Object?.keys(item?.insights).map((key) => ({
                          key,
                          value: item?.insights[key],
                        }))[0]?.value?.length ? (
                          <>
                            <View style={styles.elementTitleRow}>
                              <Text style={styles.elementText}>Element: </Text>
                              <Text style={styles.elementValueText}>
                                {" "}
                                {item?.element}
                              </Text>
                            </View>
                            <Text style={styles.elementValueText}>
                              {item?.facility || item?.lga || item?.state}
                            </Text>
                          </>
                        ) : null}

                        <View className="insight-recommendation-content">
                          <View>
                            {Object?.keys(item?.insights)
                              .map((key) => ({
                                key,
                                value: item?.insights[key],
                              }))[0]
                              .value?.map((insight) => (
                                <View>
                                  <Text>{insight}</Text>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    ) : null
                  )}
              </View>
            </>
          ) : null}

          {thirdComparable?.stateCategory &&
          thirdComparable?.stateCategoryResult?.length &&
          thirdComparable?.shouldShow ? (
            <>
              {/* <View className="main-category-title">
            {thirdComparable?.stateCategory?.name}
          </View> */}
              <View style={styles.insightRecommendationInner}>
                {thirdComparable?.stateCategoryResult
                  ?.filter((item) => {
                    if (selectedElements?.length) {
                      if (selectedElements?.includes(item?.element)) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  ?.map((item) =>
                    Object?.values(item?.insights)?.length ? (
                      <View>
                        {Object?.keys(item?.insights).map((key) => ({
                          key,
                          value: item?.insights[key],
                        }))[0]?.value?.length ? (
                          <>
                            <View style={styles.elementTitleRow}>
                              <Text style={styles.elementText}>Element: </Text>
                              <Text style={styles.elementValueText}>
                                {" "}
                                {item?.element}
                              </Text>
                            </View>
                            <Text style={styles.locationDurationTitle}>
                              {item?.facility || item?.lga || item?.state}
                            </Text>
                          </>
                        ) : null}

                        <View className="insight-recommendation-content">
                          <View>
                            {Object?.keys(item?.insights)
                              .map((key) => ({
                                key,
                                value: item?.insights[key],
                              }))[0]
                              .value?.map((insight) => (
                                <View>
                                  <Text>{insight}</Text>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    ) : null
                  )}
              </View>
            </>
          ) : null}
        </View>

        <View style={styles.recommendationRoot}>
          {firstComparable?.stateCategory &&
          firstComparable?.stateCategoryResult?.length ? (
            <>
              <Text style={styles.title}>Recommendation</Text>

              <View style={styles.insightRecommendationInner}>
                {firstComparable?.stateCategoryResult
                  ?.filter((item) => {
                    if (selectedElements?.length) {
                      if (selectedElements?.includes(item?.element)) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  ?.map((item) =>
                    Object?.values(item?.recommendation)?.length ? (
                      <View>
                        {Object?.keys(item?.recommendation).map((key) => ({
                          key,
                          value: item?.recommendation[key],
                        }))[0]?.value?.length ? (
                          <>
                            <View style={styles.elementTitleRow}>
                              <Text style={styles.elementText}>Element: </Text>
                              <Text style={styles.elementValueText}>
                                {" "}
                                {item?.element}
                              </Text>
                            </View>
                            <Text style={styles.locationDurationTitle}>
                              {item?.facility || item?.lga || item?.state}
                            </Text>
                          </>
                        ) : null}

                        <View className="insight-recommendation-content">
                          <View>
                            {Object?.keys(item?.recommendation)
                              .map((key) => ({
                                key,
                                value: item?.recommendation[key],
                              }))[0]
                              .value?.map((insight) => (
                                <Text style={styles.bulletPoint}>
                                  • {insight}
                                </Text>
                              ))}
                          </View>
                        </View>
                      </View>
                    ) : null
                  )}
              </View>
            </>
          ) : null}

          {secondComparable?.stateCategory &&
          secondComparable?.stateCategoryResult?.length &&
          secondComparable?.shouldShow ? (
            <>
              {/* <View className="main-category-title">
            {secondComparable?.stateCategory?.name}
          </View> */}
              <View style={styles.insightRecommendationInner}>
                {secondComparable?.stateCategoryResult
                  ?.filter((item) => {
                    if (selectedElements?.length) {
                      if (selectedElements?.includes(item?.element)) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  ?.map((item) =>
                    Object?.values(item?.recommendation)?.length ? (
                      <View>
                        {Object?.keys(item?.recommendation).map((key) => ({
                          key,
                          value: item?.recommendation[key],
                        }))[0]?.value?.length ? (
                          <>
                            <View style={styles.elementTitleRow}>
                              <Text style={styles.elementText}>Element: </Text>
                              <Text style={styles.elementValueText}>
                                {" "}
                                {item?.element}
                              </Text>
                            </View>
                            <Text style={styles.elementValueText}>
                              {item?.facility || item?.lga || item?.state}
                            </Text>
                          </>
                        ) : null}

                        <View className="insight-recommendation-content">
                          <View>
                            {Object?.keys(item?.recommendation)
                              .map((key) => ({
                                key,
                                value: item?.recommendation[key],
                              }))[0]
                              .value?.map((insight) => (
                                <View>
                                  <Text>{insight}</Text>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    ) : null
                  )}
              </View>
            </>
          ) : null}

          {thirdComparable?.stateCategory &&
          thirdComparable?.stateCategoryResult?.length &&
          thirdComparable?.shouldShow ? (
            <>
              {/* <View className="main-category-title">
            {thirdComparable?.stateCategory?.name}
          </View> */}
              <View style={styles.insightRecommendationInner}>
                {thirdComparable?.stateCategoryResult
                  ?.filter((item) => {
                    if (selectedElements?.length) {
                      if (selectedElements?.includes(item?.element)) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  ?.map((item) =>
                    Object?.values(item?.recommendation)?.length ? (
                      <View>
                        {Object?.keys(item?.recommendation).map((key) => ({
                          key,
                          value: item?.recommendation[key],
                        }))[0]?.value?.length ? (
                          <>
                            <View style={styles.elementTitleRow}>
                              <Text style={styles.elementText}>Element: </Text>
                              <Text style={styles.elementValueText}>
                                {" "}
                                {item?.element}
                              </Text>
                            </View>
                            <Text style={styles.locationDurationTitle}>
                              {item?.facility || item?.lga || item?.state}
                            </Text>
                          </>
                        ) : null}

                        <View className="insight-recommendation-content">
                          <View>
                            {Object?.keys(item?.recommendation)
                              .map((key) => ({
                                key,
                                value: item?.recommendation[key],
                              }))[0]
                              .value?.map((insight) => (
                                <View>
                                  <Text>{insight}</Text>
                                </View>
                              ))}
                          </View>
                        </View>
                      </View>
                    ) : null
                  )}
              </View>
            </>
          ) : null}
        </View>
      </Page>
    </Document>
  );
};

export default InsightRecommendationPdf;
