import { ReactComponent as MalariaIcon } from "../assets/images/Malaria.svg";
import { ReactComponent as RoutineImmunizationIcon } from "../assets/images/RoutineImmunization.svg";
import { ReactComponent as CoronaIcon } from "../assets/images/Corona.svg";
import { ReactComponent as RmnchIcon } from "../assets/images/RMNCH.svg";
import { ReactComponent as HivIcon } from "../assets/images/Hiv.svg";
import { ReactComponent as FamilyPlanningIcon } from "../assets/images/FamilyPlanning.svg";
import moment from "moment";

import { ReactComponent as BadEmoji } from "../assets/images/Bad.svg";

import { ReactComponent as ExcellentEmoji } from "../assets/images/Excellent.svg";
import { ReactComponent as GoodEmoji } from "../assets/images/Good.svg";
import { ReactComponent as GreatEmoji } from "../assets/images/Great.svg";
import { ReactComponent as OkayEmoji } from "../assets/images/Okay.svg";

import { ReactComponent as VeryBadEmoji } from "../assets/images/Very BAd.svg";
export const levels = ["national", "state", "lg"];

export const indicators = [
  {
    id: 1,
    name: "Number of vaccines given disaggregated by antigen, LGA and period",
  },
  { id: 2, name: "Proportion of target population vaccinated" },
  { id: 3, name: "Opened vaccine wastage" },
  { id: 4, name: "Closed Vaccine wastage" },
  { id: 5, name: "Rate of vaccine wastage (opened and closed)" },
  { id: 6, name: "Opening balance" },
  { id: 7, name: "Closing balance" },
  { id: 8, name: "Stock out" },
  { id: 9, name: "Stock balance" },
  { id: 10, name: "Number of Syringes" },
  { id: 11, name: "Doses Opened" },
];

export const program_areas = [
  {
    id: 1,
    name: "Routine Immunization",
    value: "R I",
    value2: "RI",
    icon: <RoutineImmunizationIcon style={{ width: 30, height: 30 }} />,
    active: true,
  },
  {
    id: 2,
    name: "COVID- 19",
    value: "Covid-19",
    value2: "COVID",
    icon: <CoronaIcon style={{ width: 30, height: 30 }} />,
    active: true,
  },
  {
    id: 3,
    name: "Malaria",
    value: "Malaria",
    value2: "Malaria",
    icon: <MalariaIcon style={{ width: 30, height: 30 }} />,
    active: false,
  },

  {
    id: 4,
    name: "RMNCH",
    value: "RMNCH",
    value2: "RMNCH",
    icon: <RmnchIcon style={{ width: 30, height: 30 }} />,
    active: false,
  },
  {
    id: 5,
    name: "HIV",
    value: "HIV",
    value2: "HIV",
    icon: <HivIcon style={{ width: 30, height: 30 }} />,
    active: false,
  },
  // {
  //   id: 6,
  //   name: "Family Planning",
  //   value: "F P",
  //   icon: <FamilyPlanningIcon style={{ width: 30, height: 30 }} />,
  // },
];

export const datePeriodOptions = [
  {
    label: "Last Month",
    value: "LAST MONTH",
    id: "LAST MONTH",
    startDate: moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
    resolution: "day",
  },
  {
    label: "Last Quarter",
    value: "LAST QUARTER",
    id: "LAST QUARTER",
    startDate: moment()
      .startOf("quarters")
      .subtract(1, "quarters")
      .format("YYYY-MM-DD"),
    endDate: moment()
      .startOf("quarters")
      .subtract(1, "day")
      .format("YYYY-MM-DD"),
    resolution: "week",
  },
  {
    label: "Last 6 months",
    value: "6 MONTHS",
    id: "6 MONTHS",
    startDate: moment()
      .subtract(6, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    resolution: "month",
  },
  {
    label: "Last 12 months",
    value: "12 MONTHS",
    id: "12 MONTHS",
    startDate: moment()
      .subtract(12, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    resolution: "month",
  },
  {
    label: "Custom",
    value: "Custom",
    id: "Custom",
    startDate: moment()
      .subtract(12, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    resolution: "month",
  },
];

export const feedBacks = [
  {
    label: (
      <p style={{ color: "#D92028", fontSize: 13, fontWeight: 500 }}>
        Very Bad
      </p>
    ),
    value: "very_bad",
    emoji: <VeryBadEmoji style={{ width: 30, height: 30 }} />,
  },
  {
    label: (
      <p style={{ color: "#F0585F", fontSize: 13, fontWeight: 500 }}>Bad</p>
    ),
    value: "bad",
    emoji: <BadEmoji style={{ width: 30, height: 30 }} />,
  },
  {
    label: (
      <p style={{ color: "#F69138", fontSize: 13, fontWeight: 500 }}>Okay</p>
    ),
    value: "okay",
    emoji: <OkayEmoji style={{ width: 30, height: 30 }} />,
  },
  {
    label: (
      <p style={{ color: "#FFCD3A", fontSize: 13, fontWeight: 500 }}>Good</p>
    ),
    value: "good",
    emoji: <GoodEmoji style={{ width: 30, height: 30 }} />,
  },
  {
    label: (
      <p style={{ color: "#93CC89", fontSize: 13, fontWeight: 500 }}>Great</p>
    ),
    value: "great",
    emoji: <GreatEmoji style={{ width: 30, height: 30 }} />,
  },
  {
    label: (
      <p style={{ color: "#59B952", fontSize: 13, fontWeight: 500 }}>
        Excellent
      </p>
    ),
    value: "excellent",
    emoji: <ExcellentEmoji style={{ width: 30, height: 30 }} />,
  },
];

export const insightRecommendationText =
  "These records digitally store a patient's medical history, includingdiagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient's medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care. These records digitally store a patient’s medical history, including diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. Analyzing EHR data can provide insights into disease patterns, treatment outcomes, and opportunities for preventative care.";

export const availableStates = ["Cross River", "Jigawa"];

export const availableLgas = ["Hadejia", "Kafin Hausa"];

export const colors = ["#152d6e", "#ffc60c", "#009900", "#0066ff", "#ff9900"];
