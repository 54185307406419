import React, { useEffect, useState } from "react";
import { program_areas } from "../../../../utils/constants";
import "./BaseComponent.css";
import { ReactComponent as EmptyIndicatorIcon } from "../../../../assets/images/EmptyFileIndicator.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProgramArea,
  setPrimaryIndicators,
  setSecondaryIndicators,
  setIndicators,
  setCategories,
  setSelectedCategory,
  setSelectedElements,
} from "../../../../redux/actions/global.action";
import { AddCircle, PlusOneRounded, Replay } from "@mui/icons-material";
import globalService from "../../../../services/global.service";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";

const BaseComponent = () => {
  const dispatch = useDispatch();
  const {
    selectedProgramArea,
    primaryIndicators,
    secondaryIndicators,
    indicators,
    categories,
    selectedCategory,
    selectedElements,
  } = useSelector((state) => state.global);
  const arr = new Array(5);
  const [compareIndicator, setCompareIndicator] = useState(false);
  const [gettingElementCategories, setGettingElementCategories] =
    useState(false);

  const getElementCategories = (val) => {
    setGettingElementCategories(true);
    globalService
      .getElementCategories(val)
      .then(({ message }) => dispatch(setCategories(message)))
      .catch((err) => console.log(err))
      .finally(() => setGettingElementCategories(false));
  };

  const handleResetIndicators = () => {
    setCompareIndicator(false);
    dispatch(setSecondaryIndicators([]));
    dispatch(setPrimaryIndicators([]));
  };
  const handleSelectProgramArea = (area) => {
    if (!area.active) {
      return toast.info("Currently not available for selection");
    }
    getElementCategories(area.value2);
    dispatch(setSelectedProgramArea(area));
    handleResetIndicators();
  };

  const handleSelectCategory = (category) => {
    dispatch(setSelectedElements([]));
    dispatch(setSelectedCategory(category));
    dispatch(setIndicators(category?.elements));
  };

  const handleSelectElement = (value) => {
    if (selectedElements?.includes(value)) {
      dispatch(setSelectedElements([]));
    } else {
      dispatch(setSelectedElements([value]));
    }
  };

  function formatArray(arr) {
    if (arr.length === 0) {
      return "";
    } else if (arr.length === 1) {
      return arr[0].toString();
    } else if (arr.length === 2) {
      return arr.join(" & ");
    } else {
      const lastItem = arr.pop();
      return arr.join(", ") + " & " + lastItem;
    }
  }

  return (
    <div className="base-component-root">
      <div className="program_areas_container">
        {program_areas.map((area, index) => (
          <div
            onClick={() => handleSelectProgramArea(area)}
            className={
              selectedProgramArea?.id == area.id
                ? "selceted-program-area individual_area_container"
                : !area.active
                ? "inactive-area individual_area_container"
                : "individual_area_container"
            }
            key={index}
          >
            {area.icon} <p className="area_text">{area.name}</p>
          </div>
        ))}
      </div>
      <div className="program-area-right">
        {selectedProgramArea ? (
          <>
            <div className="program-area-header">
              <p className="program-area-title">{selectedProgramArea?.name}</p>

              {/* {compareIndicator ? (
                <div
                  className="compare-container"
                  onClick={handleResetIndicators}
                >
                  <Replay className="reset-indicator-icon" />
                  <p className="compare-text">Reset Data indicators</p>
                </div>
              ) : (
                <div
                  className="compare-container"
                  onClick={() => setCompareIndicator(true)}
                >
                  <AddCircle className="compare-icon" />
                  <p className="compare-text">Compare Data</p>
                </div>
              )} */}
            </div>
            {gettingElementCategories ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {Array(12)
                  ?.fill(null)
                  .map((item, i) => (
                    <Skeleton height={40} />
                  ))}
              </div>
            ) : categories?.length ? (
              <>
                <p
                  style={{
                    height: 20,
                    margin: 20,
                    color: "#011b60",
                    textAlign: "center",
                  }}
                >
                  {selectedCategory
                    ? "See and Compare "
                    : `Select the category of data you'd like to see under ${selectedProgramArea?.name}`}

                  <span style={{ fontWeight: 500 }}>
                    {selectedCategory && selectedCategory?.name}{" "}
                  </span>
                  {selectedCategory && selectedElements?.length ? (
                    <>
                      {" "}
                      for{" "}
                      <span style={{ fontWeight: 500, color: "#ffc60c" }}>
                        {formatArray(selectedElements)}
                      </span>
                    </>
                  ) : null}
                </p>
                <div className="indicators-container">
                  <div
                    style={{
                      width: "45%",
                      height: "calc(100vh - 250px)",
                      position: "relative",
                    }}
                  >
                    <div className="indicators_inner_container">
                      {categories?.map((category, index) => (
                        <div
                          onClick={() => handleSelectCategory(category)}
                          className="indicator-item-container"
                          key={index}
                        >
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={selectedCategory?.name == category?.name}
                          />
                          <p className="indicator-text">{category?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      width: "45%",
                      height: "calc(100vh - 250px)",
                      position: "relative",
                    }}
                  >
                    <div className="indicators_inner_container">
                      {/* {indicators?.map((indicator, index) => (
                        <div
                          onClick={() =>
                            handleSelectIndicator(indicator, "primary")
                          }
                          className="indicator-item-container"
                          key={index}
                        >
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={primaryIndicators
                              ?.map((item) => item?.dh_id)
                              .includes(indicator.dh_id)}
                            onChange={() =>
                              handleSelectIndicator(indicator, "primary")
                            }
                          />
                          <p className="indicator-text">
                            {indicator?.dh_indicator}
                          </p>
                        </div>
                      ))} */}
                      {selectedCategory?.elements?.map((element, index) => (
                        <div
                          onClick={() => handleSelectElement(element?.name)}
                          className="indicator-item-container"
                          key={index}
                        >
                          <input
                            className="checkbox"
                            type="checkbox"
                            checked={selectedElements?.includes(element?.name)}
                            onChange={() => handleSelectElement(element?.name)}
                          />
                          {console.log(selectedElements, element?.name)}
                          <p className="indicator-text">{element?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="empty-container">
                <EmptyIndicatorIcon style={{ width: 100, height: 100 }} />
                <p style={{ color: "#011B60" }}>
                  There are no indicators available for{" "}
                  {selectedProgramArea?.name}
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="empty-container">
            <EmptyIndicatorIcon style={{ width: 100, height: 100 }} />
            <p style={{ color: "#011B60" }}>
              Kindly select a program area to view indicators
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseComponent;
