import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../../redux/actions/global.action";
import ShowMore from "../ShowMore";

const Insight = () => {
  const {
    stateInFocus,
    primaryIndicators,
    periods,
    firstComparable,
    secondComparable,
    thirdComparable,
    selectedLevel,
    selectedCategory,
    selectedElements,
  } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const handleViewDetails = (item, category) => {
    const fullText = Object?.keys(item?.insights)?.map((key) => ({
      key,
      value: item?.insights[key],
    }))[1]?.value;
    const region = item?.facility || item?.lga || item?.state;
    const element = item?.element;
    dispatch(togglePopup(true));
    dispatch(
      setPopupChildren(
        <ShowMore
          text={fullText}
          region={region}
          element={element}
          category={category}
        />
      )
    );
  };

  return (
    <div className="insight-recommendation-root">
      {firstComparable?.stateCategory &&
      firstComparable?.stateCategoryResult?.length ? (
        <>
          <div className="main-category-title">
            {firstComparable?.stateCategory?.name}
          </div>
          <div className="insight-recommendation-inner">
            {firstComparable?.stateCategoryResult
              ?.filter((item) => {
                if (selectedElements?.length) {
                  if (selectedElements?.includes(item?.element)) {
                    return item;
                  }
                } else {
                  return item;
                }
              })
              ?.map((item) =>
                Object?.values(item?.insights)?.length ? (
                  <div>
                    {Object?.keys(item?.insights).map((key) => ({
                      key,
                      value: item?.insights[key],
                    }))[0]?.value?.length ? (
                      <>
                        <div className="element-title-row">
                          <div className="element-text">Element: </div>
                          <div className="element-value-text">
                            {" "}
                            {item?.element}
                          </div>
                        </div>
                        <div className="location-duration-title">
                          {item?.facility || item?.lga || item?.state}
                        </div>
                      </>
                    ) : null}

                    <div className="insight-recommendation-content">
                      <ul>
                        {Object?.keys(item?.insights)
                          .map((key) => ({
                            key,
                            value: item?.insights[key],
                          }))[0]
                          .value?.map((insight) => (
                            <ShowMore text={insight} maxCharCount={60} />
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : null}

      {secondComparable?.stateCategory &&
      secondComparable?.stateCategoryResult?.length &&
      secondComparable?.shouldShow ? (
        <>
          {/* <div className="main-category-title">
            {secondComparable?.stateCategory?.name}
          </div> */}
          <div className="insight-recommendation-inner">
            {secondComparable?.stateCategoryResult
              ?.filter((item) => {
                if (selectedElements?.length) {
                  if (selectedElements?.includes(item?.element)) {
                    return item;
                  }
                } else {
                  return item;
                }
              })
              ?.map((item) =>
                Object?.values(item?.insights)?.length ? (
                  <div>
                    {Object?.keys(item?.insights).map((key) => ({
                      key,
                      value: item?.insights[key],
                    }))[0]?.value?.length ? (
                      <>
                        <div className="element-title-row">
                          <div className="element-text">Element: </div>
                          <div className="element-value-text">
                            {" "}
                            {item?.element}
                          </div>
                        </div>
                        <div className="location-duration-title">
                          {item?.facility || item?.lga || item?.state}
                        </div>
                      </>
                    ) : null}

                    <div className="insight-recommendation-content">
                      <ul>
                        {Object?.keys(item?.insights)
                          .map((key) => ({
                            key,
                            value: item?.insights[key],
                          }))[0]
                          .value?.map((insight) => (
                            <ShowMore text={insight} maxCharCount={60} />
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : null}

      {thirdComparable?.stateCategory &&
      thirdComparable?.stateCategoryResult?.length &&
      thirdComparable?.shouldShow ? (
        <>
          {/* <div className="main-category-title">
            {thirdComparable?.stateCategory?.name}
          </div> */}
          <div className="insight-recommendation-inner">
            {thirdComparable?.stateCategoryResult
              ?.filter((item) => {
                if (selectedElements?.length) {
                  if (selectedElements?.includes(item?.element)) {
                    return item;
                  }
                } else {
                  return item;
                }
              })
              ?.map((item) =>
                Object?.values(item?.insights)?.length ? (
                  <div>
                    {Object?.keys(item?.insights).map((key) => ({
                      key,
                      value: item?.insights[key],
                    }))[0]?.value?.length ? (
                      <>
                        <div className="element-title-row">
                          <div className="element-text">Element: </div>
                          <div className="element-value-text">
                            {" "}
                            {item?.element}
                          </div>
                        </div>
                        <div className="location-duration-title">
                          {item?.facility || item?.lga || item?.state}
                        </div>
                      </>
                    ) : null}

                    <div className="insight-recommendation-content">
                      <ul>
                        {Object?.keys(item?.insights)
                          .map((key) => ({
                            key,
                            value: item?.insights[key],
                          }))[0]
                          .value?.map((insight) => (
                            <ShowMore text={insight} maxCharCount={60} />
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Insight;
