import { ArrowDropDown, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { faqs } from "./constants";
import "./FaqAndHelp.css";

const FaqAndHelp = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="faq-help-root-container">
      <div className="faq-inner-container">
        <h3 className="faq-title">Frequently Asked Questions</h3>
        <div className="category-top">
          <div
            style={{
              overflow: "auto",
            }}
          >
            <div className="top-category-cont">
              {faqs.map((faq, i) => (
                <button
                  key={i}
                  onClick={() => setActiveIndex(i)}
                  className={
                    activeIndex == i ? "category-btn-selected" : "category-btn"
                  }
                >
                  {faq.theme}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="faq-main-section-container">
          <div className="faq-left-side">
            {faqs[activeIndex].questions
              .filter((_, index) => index % 2 === 0)
              .map((question, i) => (
                <div className="question-main-container">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDown className="drop-down-icon" />}
                      aria-controls={`panel${activeIndex}-${i}a-content`}
                      id={`panel${activeIndex}-${i}a-header`}
                    >
                      <p className="faq-question-text">{question.question}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="faq-answer-text">{question.answer}</p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </div>
          <div className="faq-right-side">
            {faqs[activeIndex].questions
              .filter((_, index) => index % 2 !== 0)
              .map((question, i) => (
                <div className="question-main-container">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDown className="drop-down-icon" />}
                      aria-controls={`panel${activeIndex}-${i}a-content`}
                      id={`panel${activeIndex}-${i}a-header`}
                    >
                      <p className="faq-question-text">{question.question}</p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p className="faq-answer-text">{question.answer}</p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAndHelp;
