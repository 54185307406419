import { makeStyles } from "@mui/styles";

const drawerWidth = 240;
export const TopBarStyle = makeStyles((theme) => ({
  topBarWrapper: {
    backgroundColor: "#fff",
    height: 70,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
