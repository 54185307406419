import env from "../env";
import { request } from "./api";

const { API_URL } = env;

const URL = {
  login: `${API_URL}/data.vac.login`,
  logout: `${API_URL}/data.vac.logout`,
  changePassword: `${API_URL}/data.vac.changePassword`,
};

const login = (email, password) => {
  return request(URL.login, { email, password }, "POST");
};
const logout = (email, password) => {
  return request(URL.logout, { email }, "POST");
};

const changePassword = (
  email,
  current_password,
  new_password,
  confirm_password
) => {
  return request(
    URL.changePassword,
    { email, current_password, new_password, confirm_password },
    "POST"
  );
};

const authService = {
  login,
  logout,
  changePassword,
};
export default authService;
