import env from "../env";
import { request } from "./api";

const { API_URL } = env;

const URL = {
  getIndicators: `${API_URL}/data.vac.indicators`,
  getStates: `${API_URL}/data.vac.states`,
  getLgasInState: `${API_URL}/data.vac.stateLgas`,
  getFacilitiesInLga: `${API_URL}/data.vac.lgaFacilities`,
  getAllStatesData: `${API_URL}/data.vac.statesData`,
  getSelectedStatesData: `${API_URL}/data.vac.stateData`,
  getAllLgasData: `${API_URL}/data.vac.lgasData`,
  getSelectedLgasData: `${API_URL}/data.vac.lgaData`,
  getAllFacilitiesDataInLga: `${API_URL}/data.vac.lgaFacilitiesData`,
  getSelectedFacilitiesData: `${API_URL}/data.vac.facilityData`,
  getActiveStates: `${API_URL}/data.vac.activeStates`,
  getActiveLgasInState: `${API_URL}/data.vac.activeLgas`,
  getElementCategories: `${API_URL}/data.vac.elementsCategories`,
  getRiStateData: `${API_URL}/data.vac.riState`,
  getRiLgaData: `${API_URL}/data.vac.riLga`,
  getRiFacilityData: `${API_URL}/data.vac.riFacility`,
};

const getIndicators = () => {
  return request(URL.getIndicators, {}, "GET");
};
const getStates = () => {
  return request(URL.getStates, {}, "GET");
};
const getLgasInState = (stateId) => {
  return request(URL.getLgasInState, { stateId }, "GET");
};

const getFacilitiesInLga = (lgaId) => {
  return request(URL.getFacilitiesInLga, { lgaId }, "GET");
};

const getAllStatesData = (indicatorId, dateTo, dateFrom) => {
  return request(
    URL.getAllStatesData,
    { indicatorId, dateTo, dateFrom },
    "GET"
  );
};

const getSelectedStatesData = (indicatorId, dateTo, dateFrom, stateId) => {
  return request(
    URL.getSelectedStatesData,
    { indicatorId, dateTo, dateFrom, stateId },
    "GET"
  );
};

const getAllLgasData = (indicatorId, dateTo, dateFrom, stateId) => {
  return request(
    URL.getAllLgasData,
    { indicatorId, dateTo, dateFrom, stateId },
    "GET"
  );
};

const getSelectedLgasData = (indicatorId, dateTo, dateFrom, lgaId) => {
  return request(
    URL.getSelectedLgasData,
    { indicatorId, dateTo, dateFrom, lgaId },
    "GET"
  );
};

const getAllFacilitiesDataInLga = (indicatorId, dateTo, dateFrom, lgaId) => {
  return request(
    URL.getAllFacilitiesDataInLga,
    { indicatorId, dateTo, dateFrom, lgaId },
    "GET"
  );
};
const getSelectedFacilitiesData = (
  indicatorId,
  dateTo,
  dateFrom,
  facilityId
) => {
  return request(
    URL.getSelectedFacilitiesData,
    { indicatorId, dateTo, dateFrom, facilityId },
    "GET"
  );
};

const getActiveStates = () => {
  return request(URL.getActiveStates, {}, "GET");
};

const getActiveLgasInState = (stateId) => {
  return request(URL.getActiveLgasInState, { stateId }, "GET");
};

const getElementCategories = (com) => {
  return request(URL.getElementCategories, { com }, "GET");
};

const getRiStateData = (com, ctype, stateId, months) => {
  return request(URL.getRiStateData, { com, ctype, stateId, months }, "GET");
};

const getAllRiStateData = (com, ctype, months) => {
  return request(URL.getRiStateData, { com, ctype, months }, "GET");
};

const getRiLgaData = (com, ctype, stateId, lgaId, months) => {
  return request(
    URL.getRiLgaData,
    { com, ctype, stateId, lgaId, months },
    "GET"
  );
};

const getAllRiLgaDataInState = (com, ctype, stateId, months) => {
  return request(URL.getRiLgaData, { com, ctype, stateId, months }, "GET");
};

const getRiFacilityData = (com, ctype, stateId, lgaId, facilityId, months) => {
  return request(
    URL.getRiFacilityData,
    { com, ctype, stateId, lgaId, facilityId, months },
    "GET"
  );
};

const globalService = {
  getIndicators,
  getStates,
  getLgasInState,
  getFacilitiesInLga,
  getAllStatesData,
  getSelectedStatesData,
  getAllLgasData,
  getSelectedLgasData,
  getSelectedFacilitiesData,
  getActiveStates,
  getActiveLgasInState,
  getAllFacilitiesDataInLga,
  getElementCategories,
  getRiStateData,
  getRiLgaData,
  getRiFacilityData,
  getAllRiLgaDataInState,
  getAllRiStateData,
};
export default globalService;
