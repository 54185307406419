import React, { useState } from "react";
import "./ManagePasswordModal.css";
import { Avatar } from "@mui/material";
import {
  HighlightOffOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../../../../redux/actions/global.action";
import authService from "../../../../../services/auth.service";
import { getUser } from "../../../../../utils/helpers";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";

export default function ManagePasswordModal() {
  const [cShow, setCShow] = useState(false);
  const [nShow, setNShow] = useState(false);
  const [ncShow, setNcShow] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [changingPassword, setChangingPassword] = useState(false);
  const dispatch = useDispatch();
  const user = getUser();

  const onChangePassword = () => {
    setChangingPassword(true);
    authService
      .changePassword(user?.email, password, newPassword, confirmPassword)
      .then(({ message }) => {
        if (message.status == "200") {
          toast.success(message.message);
          dispatch(togglePopup(false));
        } else {
          toast.error(message.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setChangingPassword(false);
      });
  };
  return (
    <div className="ManagePasswordModal_wrapper">
      <div className="ManagePasswordModal_header">
        <span>Change Password</span>
        <div onClick={() => dispatch(togglePopup(false))}>
          <HighlightOffOutlined
            sx={{
              color: "#011B60",
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
          />
        </div>
      </div>
      <div className="ManagePasswordModal_formWrapper">
        <div className="ManagePasswordModal_textWrapper">
          <div className="ManagePasswordModal_inputWrapper">
            <label htmlFor="">Current Password</label>
            <div className="ManagePasswordModal_password">
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={cShow ? "text" : "password"}
              />
              <div
                className="ManagePasswordModal_showPassword"
                onClick={() => setCShow(!cShow)}
              >
                {!cShow ? (
                  <VisibilityOutlined color="disabled" />
                ) : (
                  <VisibilityOffOutlined color="disabled" />
                )}
              </div>
            </div>
          </div>
          <div className="ManagePasswordModal_inputWrapper">
            <label htmlFor="">New Password</label>
            <div className="ManagePasswordModal_password">
              <input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={nShow ? "text" : "password"}
              />
              <div
                className="ManagePasswordModal_showPassword"
                onClick={() => setNShow(!nShow)}
              >
                {!nShow ? (
                  <VisibilityOutlined color="disabled" />
                ) : (
                  <VisibilityOffOutlined color="disabled" />
                )}
              </div>
            </div>
          </div>
          <div className="ManagePasswordModal_inputWrapper">
            <label htmlFor="">Confirm Password</label>
            <div className="ManagePasswordModal_password">
              <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={ncShow ? "text" : "password"}
              />
              <div
                className="ManagePasswordModal_showPassword"
                onClick={() => setNcShow(!ncShow)}
              >
                {!ncShow ? (
                  <VisibilityOutlined color="disabled" />
                ) : (
                  <VisibilityOffOutlined color="disabled" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ManagePasswordModal_buttonWrapper">
          <button
            onClick={() => dispatch(togglePopup(false))}
            className="ManagePasswordModal_leftBtn"
          >
            Cancel
          </button>
          <button
            onClick={onChangePassword}
            className="ManagePasswordModal_rightBtn"
          >
            {changingPassword ? (
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
