import React, { useState } from "react";
import PopupModal from "../../components/PopupModal";
import "./Login.css";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  setSelectedLevel,
  togglePopup,
} from "../../redux/actions/global.action";
import { setToken, setUser, setUserLogin } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { ForgottenPassword } from "./components";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import authService from "../../services/auth.service";
import { RotatingLines } from "react-loader-spinner";
import { setMyData } from "../../redux/actions/user.action";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  const handleLogin = () => {
    if (!email.trim().length) {
      return toast.error("Invalid email");
    }

    if (!password.trim().length) {
      return toast.error("Invalid password");
    }
    setLoggingIn(true);
    authService
      .login(email, password)
      .then(({ message, home_page, full_name }) => {
        if (message.success) {
          toast.success("Successfully logged in");
          setToken(`${message.api_key}:${message.api_secret}`);
          setUser({ ...message, home_page, full_name });
          dispatch(setMyData({ ...message, home_page, full_name }));
          navigate("/");
        } else {
          toast.error(message.message);
        }
      })
      .catch(({ error }) => {
        toast.error(error);
      })
      .finally(() => setLoggingIn(false));
  };
  return (
    <div className="login-root-container">
      <div className="Login_Wrapper">
        <div className="Login_Card_Background">
          <div className="Login_Card">
            <div className="Login_Header">
              <span className="Login_Header_Logo">VacInsights</span>
              <span className="Login_Header_FormTitle">Login</span>
            </div>
            <div className="Login_Form">
              <div className="Login_TextWrapper">
                <label className="Login_Form_Label" htmlFor="">
                  Email/ Username
                </label>
                <input
                  value={email}
                  className="Login_Form_Input"
                  type="text"
                  name=""
                  id=""
                  onChange={({ target }) => setEmail(target.value)}
                />
              </div>

              <div className="Login_TextWrapper">
                <label className="Login_Form_Label" htmlFor="">
                  Password
                </label>
                <input
                  value={password}
                  className="Login_Form_Input"
                  type="password"
                  onChange={({ target }) => setPassword(target.value)}
                />
              </div>
              <div
                className="Login_Form_ForgottenPassword"
                onClick={() => {
                  dispatch(setPopupChildren(<ForgottenPassword />));
                  dispatch(togglePopup(true));
                }}
              >
                <span>Forgot Password ?</span>
              </div>
              <button
                // onClick={() => {
                //   setUserLogin();
                //   navigate("/");
                // }}
                onClick={handleLogin}
                className="Login_LoginBtn"
              >
                {loggingIn ? (
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                    visible={true}
                  />
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </div>
        </div>
        <PopupModal />
      </div>
    </div>
  );
}
