export const faqs = [
    {
      theme: "General information",
      questions: [
        {
          question: "What is the purpose of the VacInsight platform?",
          answer:
            "The purpose of VacInsight is to assist data managers and decision-makers in making informed and effective decisions in RI and other program areas by providing data analysis, insights, and recommendations. It integrates data from data sources such as DHIS 2, Open LMIS, and EMID and streamlines the decision-making process, saves time, and resources, and ensures consistency and standardization in decision outcomes. Most importantly, the platform empowers users with valuable insights, enhancing the quality of decision-making in various program areas",
        },
        {
          question: "Who is the intended audience for this platform?",
          answer:
            "The decision-makers and data managers at the LGA, state, and national level",
        },
        {
          question: "How does the platform work?",
          answer:
            "Once the Users log in, they select their state. They need to provide relevant information such as program areas, indicator (s) of interest, location, and period and then update. A visualization based on the selected information is visualized, and insights and recommendations are provided. \n An interesting aspect of this platform is that they can compare data across, periods, locations, or even data sources ",
        },
        {
          question: "What are the benefits of using the VacInsight Platform?",
          answer:
            "VacInsight handles complexity by analyzing multiple indicators, enabling users to manage intricate information more effectively. They promote evidence-based decision-making, relying on data and objective information rather than intuition alone. They visualize data of interest, provide insights, and proffer recommendations",
        },
      ],
    },
  
    {
      theme: "Getting Started",
      questions: [
        {
          question: "How do I access the VacInsight Platform?",
          answer:
            "User login details are provided to specific data managers and decision-makers at the LGA, state and national level. Only those  given administrative access can login to the platform",
        },
        {
          question: "What are the system requirements to use the platform?",
          answer:
            "To use the VacInsight platform, users typically need a computer or mobile device with internet connectivity, a modern web browser such as Google Chrome, Edge, Mozilla Firefox, sufficient processing power and memory.",
        },
        {
          question: "Do I need to create an account to use the platform?",
          answer:
            "No, an account will be created for you by an administrator. Contact the admin on VacInsight@sydani.org to be granted access to the platform",
        },
      ],
    },
    {
      theme: "Data Input and Sources",
      questions: [
        {
          question: "What data do I need to input to use the platform?",
          answer:
            "You don’t need to input data. The VacInsight platform integrates with other data sources and pull data off those sources. ",
        },
        {
          question: "Are there specific data formats required for input?",
          answer:
            "There are not. The VacInsight platform already pulls relevant data for other data sources, all you need to do is select the indicator of your choice and visualize",
        },
        {
          question: "Where can I find reliable sources for the required data?",
          answer:
            "On the VacInsight platform. data visualized and information given are from credible and reliable sources.",
        },
      ],
    },
    {
      theme: "Platform Features and Functionality",
      questions: [
        {
          question: "What are the different features available in the platform?",
          answer:
            "There are different features on the VacInsight platform ranging from the chatbot; selection of indicators based on your preferred location and time period; comparison feature -where you can compare indicators across periods, locations, and even data sources; visualization; insights; and even recommendations ",
        },
        {
          question: "How can I customize the platform to suit my specific needs?",
          answer:
            "You can customize the platform to suit your specific needs by selecting your preferred indicator, location, period and explore the comparison feature",
        },
        {
          question: "Can I save my progress and return to it later?",
          answer:
            "Yes, you can. Your progress is automatically saved as you use the platform. Please note that once your session expires, you would be required to start all over again",
        },
      ],
    },
    {
      theme: "Interpreting Results",
      questions: [
        {
          question: "How are the results generated by the platform presented?",
          answer:
            "The results generated by the platform are visualized in charts. The and recommendations are summarized and presented in bullet points, to get the full insight, double-click on the preferred bullet point ",
        },
        {
          question: "Are there guidelines for interpreting the results?",
          answer:
            "Yes, there are. Once your result has been visualized, insight and recommendations will be provided detailing the interpretation of your results as well as what you can do.",
        },
      ],
    },
    {
      theme: "Platform Accuracy and Limitations",
      questions: [
        {
          question:
            "How accurate are the predictions or outcomes provided by the platform?",
          answer: "The predictions and outcomes are 98% accurate",
        },
        {
          question: "What are the limitations of the platform's predictions?",
          answer:
            "the platform provides insights and possibles recommendations based on your selected indicator, location and period. The recommendations can then be contextualized with local context by the user before the decision is taken. As a technological solution, the platform is limited because the insights and recommendations generated are primarily from the data pulled from existing data sources and may not factor in local context or data from other sources not configured.",
        },
      ],
    },
    {
      theme: "Privacy and Security",
      questions: [
        {
          question: "How is my data protected while using the platform?",
          answer:
            "The platform in its design and functionality aligns with international General Data Protection Regulations (GDPR) and standards. Your data is secured in encrypted, and password protected servers.",
        },
        {
          question: "Is my personal information stored or shared?",
          answer:
            "Your personal information is stored, this way the team can provide on-the-job mentoring and provide technical support when needed.",
        },
      ],
    },
    {
      theme: "Troubleshooting",
      questions: [
        {
          question:
            "What should I do if I encounter technical issues while using the platform?",
          answer:
            "1.  Check your internet connection and ensure it is stable and working properly \n 2.  Refresh the page or close and reopen the platform to see if the issue resolves \n 3.  If the problem persists, contact the platform's technical team, and provide details about the issue, including any error messages received, to receive prompt assistance and resolution",
        },
        {
          question: "How can I report a bug or error in the platform?",
          answer:
            "1.  Identify the Issue: Clearly identify and document the bug or error you encountered. Note down any error messages unexpected behaviors, or specific steps that led to the problem. \n2.  Contact Customer Support: Reach out to the VacInsight’s customer support team. This can typically be done through a Contact \"Us\" or \"Help\" section on the platform's website or application. \n3.  Provide Details: When reporting the bug, be specific and provide as much detail as possible. Include information about the device, operating system, and web browser you were using, along with any relevant screenshots or error logs. \n4.  Describe the Steps to Reproduce: Explain the steps you took that led to the bug or error. This will help the platform's technical team replicate the issue and identify the root cause. \n5.  Share Contact Information: Provide your contact information so that the support team can reach out to you for further clarification or updates on the bug's resolution. \n6.  Stay Engaged: Stay engaged with the support team and follow up on the progress of the bug resolution. Be open to providing additional information if needed to help the team address the issue effectively. By promptly reporting bugs or errors, you contribute to improving the decision-aid platform's performance and ensuring a better user experience for all users.",
        },
      ],
    },
    {
      theme: "Privacy and Security",
      questions: [
        {
          question: "How is my data protected while using the platform?",
          answer:
            "The platform in its design and functionality aligns with international General Data Protection Regulations (GDPR) and standards. Your data is secured in encrypted, and password protected servers.",
        },
        {
          question: "Is my personal information stored or shared?",
          answer:
            "Your personal information is stored, this way the team can provide on-the-job mentoring and provide technical support when needed.",
        },
      ],
    },
    {
      theme: "Contact and Support",
      questions: [
        {
          question:
            "How can I get in touch with customer support for assistance?",
          answer:
            '1.  *Contact Information on the Website:* Check the VacInsight platform for a "Contact Us" or "Support" page. This page typically provides contact details, such as an email address, and phone number \n2.  *Live Chat:* VacInsight offers a live chat option on the platform, allowing you to chat with a customer support representative in real-time for immediate assistance. \n3.  *Email Support:* There is an email address provided for customer support, you can send an email describing your issue and requesting assistance. \nWhen contacting customer support, be clear and specific about the problem you are facing, and provide any relevant information, such as your account details or error messages, to help them understand and address your issue promptly.',
        },
  
        {
          question:
            "Are there user guides or tutorials available on the platform?",
          answer:
            "Yes, there are. For a first time user, there is an onboarding tutorial on how to easily navigate through the platform, the is also a link to a video tutorial provided for your use.",
        },
        {
          question:
            "Can I provide feedback or suggestions for improving the platform?",
          answer:
            "Yes, you can.  A feedback form has been incorporated in the webapp where you can rate the platform and provide feedback. You can also chat with our chat buddy and provide feedback.",
        },
      ],
    },
  
    {
      theme: "Updates and Maintenance",
      questions: [
        {
          question:
            "How often is the platform updated with data, new features, or improvements?",
          answer:
            "The platform frequently updates data weekly and monthly. New features and functions will be made incorporated in the platform as we continue to get your feedback and make improvements.",
        },
        {
          question:
            "Is there any scheduled maintenance that may impact access to the platform?",
          answer:
            "Yes, there might be scheduled maintenance periods that could impact access to the platform. During scheduled maintenance, the platform may be temporarily unavailable to users.  \nThese maintenance periods are essential for the platform's developers and administrators to perform updates, implement improvements, address technical issues, or optimize performance. The technical team will strive to schedule maintenance during periods of low user activity to minimize disruptions. We will inform users in advance about the scheduled maintenance through notifications",
        },
      ],
    },
  ];