import React, { useEffect } from "react";
import { Box, Modal } from "@mui/material";
import "./PopupModal.css";
const PopupModal = ({
  showPopup,
  popupChildren,
  togglePopup,
  setPopupChildren,
  calling,
}) => {
  useEffect(() => {
    if (showPopup === false) {
      setPopupChildren(null);
    }
  }, [showPopup]);

  return (
    <Modal
      disableAutoFocus={true}
      className="modal-class"
      open={showPopup}
      onClose={() => togglePopup(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100vh",
      }}
    >
      <Box>{popupChildren}</Box>
    </Modal>
  );
};

export default PopupModal;
