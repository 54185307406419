import { RadioButtonCheckedOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./FirstTimeInfo.css";
import Step1 from "../../assets/images/gettingStarted/Step 1.png";

import Step2 from "../../assets/images/gettingStarted/Step 2.png";
import Step3 from "../../assets/images/gettingStarted/Step 3.png";
import Step4 from "../../assets/images/gettingStarted/Step 4.png";
import Step5 from "../../assets/images/gettingStarted/Step 5.png";
import { togglePopup } from "../../redux/actions/global.action";
import { setDontShowIntro } from "../../utils/helpers";

const FirstTimeInfo = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const nextStep = () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      dispatch(togglePopup(false));
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <div className="first-time-container">
      <div className="first-time-inner">
        <h2 className="header-title">Welcome to VacInsights</h2>
        <p style={{ marginBottom: 16 }}>
          To get started follow this easy steps
        </p>
      </div>
      <div className="progress-step-container">
        <div
          onClick={() => setStep(1)}
          className={`progress-step ${step >= 1 ? "active" : ""}`}
        >
          <RadioButtonCheckedOutlined sx={{ height: 13 }} /> Step 1
        </div>
        <div
          onClick={() => setStep(2)}
          className={`progress-step ${step >= 2 ? "active" : ""}`}
        >
          <RadioButtonCheckedOutlined sx={{ height: 13 }} /> Step 2
        </div>
        <div
          onClick={() => setStep(3)}
          className={`progress-step ${step >= 3 ? "active" : ""}`}
        >
          <RadioButtonCheckedOutlined sx={{ height: 13 }} /> Step 3
        </div>
        <div
          onClick={() => setStep(4)}
          className={`progress-step ${step >= 4 ? "active" : ""}`}
        >
          <RadioButtonCheckedOutlined sx={{ height: 13 }} /> Step 4
        </div>
        <div
          onClick={() => setStep(5)}
          className={`progress-step ${step >= 5 ? "active" : ""}`}
        >
          <RadioButtonCheckedOutlined sx={{ height: 13 }} /> Step 5
        </div>
      </div>
      <div className="step-title">
        {step === 1 && "Select State  from the map"}
        {step === 2 &&
          "Select program area and a maximun of 3 indicators you'd like to compare"}
        {step === 3 &&
          "Select desired location: State(s), Lga(s) and/or Facility(s)"}
        {step === 4 && "Select period in which you'd like to see result for"}
        {step === 5 && "View result"}
      </div>
      <div className="main-container">
        {step === 1 && (
          <div className="step-container">
            <img src={Step1} className="step-image" />
          </div>
        )}
        {step === 2 && (
          <div className="step-container">
            <img src={Step2} className="step-image" />
          </div>
        )}
        {step === 3 && (
          <div className="step-container">
            <img src={Step3} className="step-image" />
          </div>
        )}
        {step === 4 && (
          <div className="step-container">
            <img src={Step4} className="step-image" />
          </div>
        )}
        {step === 5 && (
          <div className="step-container">
            <img src={Step5} className="step-image" />
          </div>
        )}
      </div>
      <div
        className={`flex ${step < 2 ? "justify-end" : "justify-between"} my-4`}
        style={{
          display: "flex",
          marginTop: "8px",
          marginBottom: "8px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {step > 1 ? (
          <button
            size="large"
            onClick={() => prevStep()}
            type="light"
            className="next-prev-button prev-btn"
          >
            Previous
          </button>
        ) : (
          <div />
        )}
        <p
          onClick={() => {
            setDontShowIntro(true);
            dispatch(togglePopup(false));
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: 13,
          }}
        >
          Don't Show this again
        </p>
        {step < 5 ? (
          <button
            size="large"
            onClick={() => nextStep()}
            type="light"
            className="next-prev-button nxt-btn"
          >
            Next
          </button>
        ) : (
          <button
            size="large"
            onClick={() => nextStep()}
            type="light"
            className="next-prev-button end-btn"
          >
            End
          </button>
        )}
      </div>
    </div>
  );
};

export default FirstTimeInfo;
