import React, { useState } from "react";
import { TopBarStyle } from "./TopBar.style";
import { Avatar, Box, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProfileOptions } from "./components";
import "./Topbar.css";

const TopBar = () => {
  const classes = TopBarStyle();
  const pages = [
    { label: "Home", path: "/", value: "Home" },
    { label: "About VacInsights", path: "/about", value: "about" },
    // { label: "Program Areas", path: "/program_areas", value: "program_areas" },
    { label: "FAQs & Support", path: "/faqs_support", value: "faqs_support" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.pathname.split("/")[1];
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className={classes.topBarWrapper}>
      <div>
        <h1
          style={{
            fontWeight: 600,
            marginLeft: 50,
            color: "#284286",
            fontFamily: "Means",
            fontSize: 22,
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          {" "}
          VacInsights
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ul
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 50,
            listStyleType: "none",
            marginRight: 50,
          }}
        >
          {pages.map((page, index) => (
            <li key={index}>
              <Link
                style={{
                  textDecoration: "none",
                  fontSize: 14,
                  color: "#011B60",
                  fontWeight:
                    currentPage == page.path.split("/")[1] ? 600 : 400,
                }}
                to={page.path}
              >
                {page.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="topbarAvatar" onClick={() => setShowMenu(!showMenu)}>
          <Avatar style={{ marginRight: 40 }} />
          {showMenu && (
            <div className="topbarMenu">
              <ProfileOptions />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
