import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setChart,
  setFirstComparable,
  setGettingChartData,
  setSecondComparable,
  setSelectedFacilities,
  setSelectedLgas,
  setSelectedStates,
  setThirdComparable,
} from "../../../redux/actions/global.action";
import globalService from "../../../services/global.service";
import {
  getMonthsBetweenDates,
  groupedByFacility,
  groupedByLGA,
  groupedByState,
} from "../../../utils/helpers";
import BaseComponent from "../components/BaseComponent";
import Location from "../components/Location";
import Period from "../components/Period";
import Summary from "../components/Summary";
import { steps } from "../constants";

const useProgramAreas = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const {
    stateInFocus,
    primaryIndicators,
    periods,
    firstComparable,
    secondComparable,
    thirdComparable,
    selectedLevel,
    selectedCategory,
    selectedProgramArea,
  } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedLevel == "state" && !stateInFocus) {
      toast.info("Please select your preferred state to continue");
      navigate("/");
    } else {
      if (activeStep !== 0 && !selectedCategory) {
        setActiveStep(0);
        toast.info("Please select a category");
      } else {
        if (activeStep === 3) {
          if (!periods.length) {
            setActiveStep(2);
            toast.info("Please select periods in which you want to view data");
          } else {
            dispatch(
              setFirstComparable({
                ...firstComparable,
                statesResult: [],
                stateCategory: null,
                stateCategoryResult: [],
              })
            );
            dispatch(
              setSecondComparable({
                ...secondComparable,
                statesResult: [],
                stateCategory: null,
                stateCategoryResult: [],
              })
            );
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                statesResult: [],
                stateCategory: null,
                stateCategoryResult: [],
              })
            );
            const indicatorId = String(
              primaryIndicators.map((indicator) => indicator?.dh_id)
            );
            const dateTo = periods[periods?.length - 1]?.endDate;
            const dateFrom = periods[periods?.length - 1]?.startDate;
            const startDate = new Date(dateFrom);
            const endDate = new Date(dateTo);

            const monthsBetween = String(
              getMonthsBetweenDates(startDate, endDate)
            );
            const collectedDatas = [
              firstComparable,
              secondComparable,
              thirdComparable,
            ];

            dispatch(
              setSelectedStates(
                collectedDatas
                  .filter((obj) => obj.state !== null)
                  .map((obj) => obj.state)
              )
            );
            dispatch(
              setSelectedLgas(
                collectedDatas
                  .filter((obj) => obj.lga !== null)
                  .map((obj) => obj.lga)
              )
            );

            dispatch(
              setSelectedFacilities([
                ...firstComparable?.selectedFacilities,
                ...secondComparable?.selectedFacilities,
                ...thirdComparable?.selectedFacilities,
              ])
            );

            dispatch(setChart([]));
            console.log(
              "selected facilities",
              String(
                firstComparable?.selectedFacilities?.map(
                  (facility) => facility?.dh_id
                )
              )
            );
            if (
              [
                ...firstComparable?.selectedFacilities,
                ...secondComparable?.selectedFacilities,
                ...thirdComparable?.selectedFacilities,
              ]?.length
            ) {
              console.log("1st");
              if (firstComparable?.selectedFacilities?.length) {
                getRiFacilityData(
                  "first",
                  "RI",
                  selectedCategory,
                  firstComparable?.state?.dh_id,
                  firstComparable?.lga?.dh_id,
                  String(
                    firstComparable?.selectedFacilities?.map(
                      (facility) => facility?.dh_id
                    )
                  ),
                  monthsBetween
                );
              }
              if (secondComparable?.selectedFacilities?.length) {
                getRiFacilityData(
                  "second",
                  "RI",
                  selectedCategory,
                  secondComparable?.state?.dh_id,
                  secondComparable?.lga?.dh_id,
                  String(
                    secondComparable?.selectedFacilities?.map(
                      (facility) => facility?.dh_id
                    )
                  ),
                  monthsBetween
                );
              }
              if (thirdComparable?.selectedFacilities?.length) {
                getRiFacilityData(
                  "third",
                  "RI",
                  selectedCategory,
                  thirdComparable?.state?.dh_id,
                  thirdComparable?.lga?.dh_id,
                  String(
                    thirdComparable?.selectedFacilities?.map(
                      (facility) => facility?.dh_id
                    )
                  ),
                  monthsBetween
                );
              }
            } else if (
              collectedDatas
                .filter(
                  (obj) =>
                    obj.lgaAction && obj.lgaAction.value === "lga_facilities"
                )
                .map((obj) => obj.lgaAction)?.length &&
              collectedDatas
                .filter((obj) => obj.lga !== null)
                .map((obj) => obj.lga)?.length
            ) {
              console.log("2nd");
              if (
                firstComparable?.lgaAction &&
                firstComparable?.lgaAction == "lga_facilities" &&
                firstComparable?.lga
              ) {
                getAllFacilitiesDataInLga(
                  "first",
                  firstComparable?.lga?.dh_id,
                  indicatorId,
                  dateTo,
                  dateFrom
                );
              }
              if (
                secondComparable?.lgaAction &&
                secondComparable?.lgaAction == "lga_facilities" &&
                secondComparable?.lga
              ) {
                getAllFacilitiesDataInLga(
                  "second",
                  secondComparable?.lga?.dh_id,
                  indicatorId,
                  dateTo,
                  dateFrom
                );
              }
              if (
                thirdComparable?.lgaAction &&
                thirdComparable?.lgaAction == "lga_facilities" &&
                thirdComparable?.lga
              ) {
                getAllFacilitiesDataInLga(
                  "third",
                  thirdComparable?.lga?.dh_id,
                  indicatorId,
                  dateTo,
                  dateFrom
                );
              }
            } else if (
              collectedDatas
                .filter(
                  (obj) =>
                    obj.lgaAction && obj.lgaAction.value === "lga_summary"
                )
                .map((obj) => obj.lgaAction)?.length &&
              collectedDatas
                .filter((obj) => obj.lga !== null)
                .map((obj) => obj.lga)?.length
            ) {
              console.log("3rd");
              if (firstComparable?.lga) {
                getRiLgaData(
                  "first",
                  "RI",
                  selectedCategory,
                  firstComparable?.state?.dh_id,
                  firstComparable?.lga?.dh_id,
                  monthsBetween
                );
              }
              if (secondComparable?.lga) {
                getRiLgaData(
                  "second",
                  "RI",
                  selectedCategory,
                  secondComparable?.state?.dh_id,
                  secondComparable?.lga?.dh_id,
                  monthsBetween
                );
              }
              if (thirdComparable?.lga) {
                getRiLgaData(
                  "third",
                  "RI",
                  selectedCategory,
                  thirdComparable?.state?.dh_id,
                  thirdComparable?.lga?.dh_id,
                  monthsBetween
                );
              }
            } else if (
              selectedLevel !== "lga" &&
              collectedDatas
                .filter(
                  (obj) =>
                    obj.stateAction && obj.stateAction.value === "state_summary"
                )
                .map((obj) => obj.stateAction)?.length &&
              collectedDatas
                .filter((obj) => obj.state !== null)
                .map((obj) => obj.state?.dh_id)?.length
            ) {
              console.log("4th");
              if (firstComparable?.state) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "first",
                      "RI",
                      selectedCategory,
                      firstComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
              if (secondComparable?.state) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "second",
                      "RI",
                      selectedCategory,
                      secondComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }

              if (thirdComparable?.state) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "third",
                      "RI",
                      selectedCategory,
                      thirdComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
            } else if (
              selectedLevel !== "lga" &&
              collectedDatas
                .filter(
                  (obj) =>
                    obj.stateAction && obj.stateAction.value === "select_lga"
                )
                .map((obj) => obj.stateAction)?.length &&
              collectedDatas
                .filter((obj) => obj.state !== null)
                .map((obj) => obj.state?.dh_id)?.length
            ) {
              console.log("5th");
              if (
                firstComparable?.stateAction &&
                firstComparable?.stateAction == "select_lga" &&
                firstComparable?.state
              ) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "first",
                      "RI",
                      selectedCategory,
                      firstComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
              if (
                secondComparable?.stateAction &&
                secondComparable?.stateAction == "select_lga" &&
                secondComparable?.state
              ) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "second",
                      "RI",
                      selectedCategory,
                      secondComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
              if (
                thirdComparable?.stateAction &&
                thirdComparable?.stateAction == "select_lga" &&
                thirdComparable?.state
              ) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "third",
                      "RI",
                      selectedCategory,
                      thirdComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
            } else if (
              selectedLevel === "national" &&
              collectedDatas
                .filter((obj) => obj.state !== null)
                .map((obj) => obj.state.dh_id)?.length
            ) {
              console.log("6th");
              if (firstComparable?.state) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "first",
                      "RI",
                      selectedCategory,
                      firstComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
              if (secondComparable?.state) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "second",
                      "RI",
                      selectedCategory,
                      secondComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }

              if (thirdComparable?.state) {
                switch (selectedCategory?.com) {
                  case "RI":
                    getRiStateData(
                      "third",
                      "RI",
                      selectedCategory,
                      thirdComparable?.state?.dh_id,
                      monthsBetween
                    );
                    break;

                  default:
                    break;
                }
              }
            } else if (selectedLevel === "national") {
              console.log("7th");
              switch (selectedCategory?.com) {
                case "RI":
                  getAllRiStateData(
                    "first",
                    "RI",
                    selectedCategory,
                    monthsBetween
                  );
                  break;

                default:
                  break;
              }
            }
          }
        }
      }
    }
  }, [activeStep]);

  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const getAllFacilitiesDataInLga = (
    section,
    lgaId,
    indicatorId,
    dateTo,
    dateFrom
  ) => {
    dispatch(setGettingChartData(true));
    globalService
      .getAllFacilitiesDataInLga(indicatorId, dateTo, dateFrom, lgaId)
      .then(({ message }) => {
        switch (section) {
          case "first":
            dispatch(
              setFirstComparable({
                ...firstComparable,
                facilitiesResult: message,
              })
            );
            break;
          case "second":
            dispatch(
              setSecondComparable({
                ...secondComparable,
                facilitiesResult: message,
              })
            );
            break;
          case "third":
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                facilitiesResult: message,
              })
            );
            break;
          default:
            return null;
        }
      })
      .catch((error) => console.log("error in get all facilities", error))
      .finally(() => dispatch(setGettingChartData(false)));
  };

  const getRiStateData = (section, com, category, stateId, months, data) => {
    dispatch(setGettingChartData(true));
    globalService
      .getRiStateData(com, category?.name, stateId, months)
      .then(({ message }) => {
        switch (section) {
          case "first":
            dispatch(
              setFirstComparable({
                ...firstComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "second":
            dispatch(
              setSecondComparable({
                ...secondComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "third":
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          default:
            return null;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingChartData(false)));
  };

  const getAllRiStateData = (section, com, category, months) => {
    dispatch(setGettingChartData(true));
    globalService
      .getAllRiStateData(com, category?.name, months)
      .then(({ message }) => {
        switch (section) {
          case "first":
            dispatch(
              setFirstComparable({
                ...firstComparable,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "second":
            dispatch(
              setSecondComparable({
                ...secondComparable,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "third":
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          default:
            return null;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingChartData(false)));
  };

  const getRiLgaData = (
    section,
    com,
    category,
    stateId,
    lgaId,
    months,
    data
  ) => {
    dispatch(setGettingChartData(true));
    globalService
      .getRiLgaData(com, category?.name, stateId, lgaId, months)
      .then(({ message }) => {
        switch (section) {
          case "first":
            dispatch(
              setFirstComparable({
                ...firstComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "second":
            dispatch(
              setSecondComparable({
                ...secondComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "third":
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          default:
            return null;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingChartData(false)));
  };

  const getAllRiLgaDataInState = (section, com, category, stateId, months) => {
    dispatch(setGettingChartData(true));
    globalService
      .getAllRiLgaDataInState(com, category?.name, stateId, months)
      .then(({ message }) => {
        switch (section) {
          case "first":
            dispatch(
              setFirstComparable({
                ...firstComparable,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "second":
            dispatch(
              setSecondComparable({
                ...secondComparable,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "third":
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          default:
            return null;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingChartData(false)));
  };

  const getRiFacilityData = (
    section,
    com,
    category,
    stateId,
    lgaId,
    facilityId,
    months,
    data
  ) => {
    dispatch(setGettingChartData(true));
    globalService
      .getRiFacilityData(
        com,
        category?.name,
        stateId,
        lgaId,
        facilityId,
        months
      )
      .then(({ message }) => {
        console.log("this iis facilities ri ==>>", message);
        switch (section) {
          case "first":
            dispatch(
              setFirstComparable({
                ...firstComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "second":
            dispatch(
              setSecondComparable({
                ...secondComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          case "third":
            dispatch(
              setThirdComparable({
                ...thirdComparable,
                statesResult: data,
                stateCategory: category,
                stateCategoryResult: message,
              })
            );
            break;
          default:
            return null;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingChartData(false)));
  };
  const renderTab = (activeStep) => {
    switch (activeStep) {
      case 0:
        return <BaseComponent />;
      case 1:
        return <Location />;
      case 2:
        return <Period />;
      case 3:
        return <Summary />;
      default:
        return <BaseComponent />;
    }
  };

  return { activeStep, handleStep, completed, renderTab, handleNext };
};

export default useProgramAreas;
