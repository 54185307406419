import React, { useState } from "react";
import "./ShowMore.css";

const ShowMore = ({ text, maxCharCount }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const content = isExpanded ? text : text.slice(0, maxCharCount);

  return (
    <li>
      <p className="point-paragraph">{content}</p>
      {text.length > maxCharCount && (
        <button className="show-button" onClick={toggleExpansion}>
          {isExpanded ? "...show less" : "...show more"}
        </button>
      )}
    </li>
  );
};

export default ShowMore;
