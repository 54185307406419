import React from "react";
import "./Period.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { AddCircle, HighlightOff } from "@mui/icons-material";
import { datePeriodOptions } from "../../../../utils/constants";
import { IconButton } from "@mui/material";
import {
  setFirstComparable,
  setPeriods,
  setPopupChildren,
  setSecondComparable,
  setThirdComparable,
  togglePopup,
} from "../../../../redux/actions/global.action";
import DateRangeSelector from "../../../../components/DateRangeSelector/DateRangeSelector";

const Period = () => {
  const { firstComparable, secondComparable, thirdComparable } = useSelector(
    (state) => state.global
  );

  const { chart, periods } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  const handleCancelSection = (section) => {
    dispatch(
      setPeriods(periods.filter((period, i) => period.section != section))
    );
    switch (section) {
      case "first":
        dispatch(
          setFirstComparable({
            ...firstComparable,
            showDate: false,
            selectedDate: null,
          })
        );
        break;
      case "second":
        dispatch(
          setSecondComparable({
            ...secondComparable,
            showDate: false,
            selectedDate: null,
          })
        );
        break;
      case "third":
        dispatch(
          setThirdComparable({
            ...thirdComparable,
            showDate: false,
            selectedDate: null,
          })
        );
        break;
      default:
        break;
    }
  };

  const handleChangeDuration = (selected, section) => {
    if (selected.value == "Custom") {
      dispatch(
        setPopupChildren(
          <div style={{ backgroundColor: "#fff" }}>
            <DateRangeSelector section={section} />
          </div>
        )
      );
      dispatch(togglePopup(true));
    } else {
      switch (section) {
        case "first":
          dispatch(
            setFirstComparable({
              ...firstComparable,
              selectedDate: selected,
            })
          );
          break;
        case "second":
          dispatch(
            setSecondComparable({
              ...secondComparable,
              selectedDate: selected,
            })
          );
          break;
        case "third":
          dispatch(
            setThirdComparable({
              ...thirdComparable,
              selectedDate: selected,
            })
          );
          break;
        default:
          break;
      }

      if (!periods.map((period) => period?.section).includes(section)) {
        dispatch(setPeriods([...periods, { ...selected, section }]));
      } else {
        const index = periods.findIndex((period) => period.section == section);

        if (index !== -1) {
          dispatch(
            setPeriods(
              periods.map((period, i) =>
                i == index ? { ...selected, section } : period
              )
            )
          );
        }
      }
    }
  };

  return (
    <div className="period-root-container">
      <div className="inner-root-container">
        <div className="header-text-container">
          <p className="header-text">
            Kindly select specific period to view data
          </p>
        </div>

        <div className="periods-selections-wrapper">
          {firstComparable && (
            <div class="category-container">
              <div className="selection-container">
                <p className="select-label">Select Period</p>
                <Select
                  className="select-dropdown"
                  options={datePeriodOptions}
                  onChange={(val) => handleChangeDuration(val, "first")}
                  value={firstComparable?.selectedDate}
                />
              </div>
            </div>
          )}
          {/* {secondComparable?.showDate ? (
            <div class="category-container">
              <div className="selection-container">
                <IconButton
                  onClick={() => handleCancelSection("second")}
                  className="cancel-selection-btn"
                >
                  <HighlightOff color="red" />
                </IconButton>
                <p className="select-label">Select Period</p>
                <Select
                  className="select-dropdown"
                  options={datePeriodOptions}
                  onChange={(val) => handleChangeDuration(val, "second")}
                  value={secondComparable?.selectedDate}
                />
              </div>
            </div>
          ) : (
            <div class="empty-category-container">
              <div
                onClick={() =>
                  dispatch(
                    setSecondComparable({
                      ...secondComparable,
                      showDate: true,
                    })
                  )
                }
                className="compare-var-container"
              >
                <AddCircle className="compare-var-icon" />
                <p className="compare-var-text">Compare Data</p>
              </div>
            </div>
          )}
          {thirdComparable.showDate ? (
            <div class="category-container">
              <div className="selection-container">
                <IconButton
                  onClick={() => handleCancelSection("third")}
                  className="cancel-selection-btn"
                >
                  <HighlightOff color="red" />
                </IconButton>
                <p className="select-label">Select Period</p>
                <Select
                  className="select-dropdown"
                  options={datePeriodOptions}
                  onChange={(val) => handleChangeDuration(val, "third")}
                  value={thirdComparable?.selectedDate}
                />
              </div>
            </div>
          ) : (
            <div class="category-container">
              {secondComparable?.showDate && (
                <div
                  onClick={() =>
                    dispatch(
                      setThirdComparable({
                        ...thirdComparable,
                        showDate: true,
                      })
                    )
                  }
                  className="compare-var-container"
                >
                  <AddCircle className="compare-var-icon" />
                  <p className="compare-var-text">Compare Data</p>
                </div>
              )}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Period;
