import React from "react";
import "./MyProfileModal.css";
import { Avatar } from "@mui/material";
import { HighlightOffOutlined } from "@mui/icons-material";
import { togglePopup } from "../../../../../redux/actions/global.action";
import { useDispatch, useSelector } from "react-redux";

export default function MyProfileModal() {
  const dispatch = useDispatch();
  const { myData } = useSelector((state) => state.user);
  return (
    <div className="myProfileModel_wrapper">
      <div className="myProfileModel_header">
        <span>Edit Profile</span>
        <div onClick={() => dispatch(togglePopup(false))}>
          <HighlightOffOutlined
            sx={{
              color: "#011B60",
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
          />
        </div>
      </div>
      <div className="form-container">
        <form className="myProfileModel_formWrapper">
          <div className="myProfileModel_profileUploadWrapper">
            <Avatar
              sx={{ width: "50px", height: "50px", marginRight: "2rem" }}
            />
            <div className="myProfileModel_uploadWrapper">
              <label className="myProfileModel_uploadLabel">
                Upload Profile Photo
              </label>
              <input
                className="myProfileModel_uploadInput"
                type="file"
                name=""
                id=""
              />
            </div>
          </div>
          <div
            className="myProfileModel_inputWrapper"
            style={{ width: "100%" }}
          >
            <label htmlFor="">Full Name</label>
            <input value={myData?.full_name} type="text" />
          </div>
          <div className="myProfileModel_textWrapper">
            {/* <div className="myProfileModel_inputWrapper">
              <label htmlFor="">Last Name</label>
              <input type="text" />
            </div> */}
            <div className="myProfileModel_inputWrapper">
              <label htmlFor="">Email</label>
              <input value={myData?.email} type="email" />
            </div>
            <div className="myProfileModel_inputWrapper">
              <label htmlFor="">Phone Number</label>
              <input type="text" />
            </div>
          </div>
          <div className="myProfileModel_buttonWrapper">
            <button className="myProfileModel_leftBtn">Cancel</button>
            <button
              onClick={() => dispatch(togglePopup(false))}
              className="myProfileModel_rightBtn"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
