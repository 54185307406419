import { Typography } from "@mui/material";
import React from "react";
import { AboutStyle } from "./About.style";
import "./About.css";
const About = () => {
  const classes = AboutStyle();
  return (
    <div className={"about-root-container"}>
      <div style={{ marginTop: 50 }}>
        <h4 className={"title"}>About us</h4>
      </div>
      <div>
        <p className={"paragraphs"}>
          {" "}
          VacInsight Platform, is developed by the Vaccine Data CoLab in
          partnership with Sydani Group to <br />
          transform routine immunization decision-making in Nigeria.
        </p>
        <p className={"paragraphs"}>
          {" "}
          By integrating data from DHIS 2 and OpenLMIS, EMID, COVID-19 call-in
          data and SMS Stock <br /> balance data the platform offers
          comprehensive immunization insights.
        </p>
        <p className={"paragraphs"}>
          {" "}
          It empowers key decision-makers with valuable information on coverage
          indicators, stock <br /> balance, data accuracy, and specific
          vaccine-related data.
        </p>
        <p className={"paragraphs"}>
          {" "}
          Utilizing machine learning and GPT language models, it generates
          deeper insights and <br /> accurate recommendations. VacInsight
          bridges the gap between hyperlocal data and <br /> evidence-based
          decisions, strengthening the routine immunization ecosystem and
          advancing <br /> equitable healthcare outcomes nationwide.
        </p>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
        }}
      >
        <img
          alt="better_futures_colab"
          style={{ height: 140 }}
          src={require("../../assets/images/bf_colab_logo.png")}
        />
        <img
          alt="sydani_group"
          style={{ height: 80 }}
          src={require("../../assets/images/sydani_group_logo.png")}
        />
      </div>
    </div>
  );
};

export default About;
