import React from "react";
import "./ProfileOptions.css";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../redux/actions/global.action";
import MyProfileModal from "../MyProfileModal";
import ManagePasswordModal from "../ManagePasswordModal";
import { useNavigate } from "react-router-dom";
import {
  getUser,
  removeUserSession,
  setUserLogOut,
} from "../../../../../utils/helpers";

import { ReactComponent as UserProfileIcon } from "../../../../../assets/images/user-profile.svg";
import { ReactComponent as LockIcon } from "../../../../../assets/images/lock.svg";
import { ReactComponent as LogoutIcon } from "../../../../../assets/images/logout.svg";
import authService from "../../../../../services/auth.service";
import { toast } from "react-toastify";
export default function ProfileOptions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getUser();
  const handleLogout = () => {
    if (user) {
      authService
        .logout(user?.email)
        .then(({ message }) => {
          removeUserSession();
          toast.success("Successfully logged out");
          navigate("/login");
        })
        .then(() => {
          removeUserSession();
          navigate("/login");
        });
    }
    removeUserSession();
    navigate("/login");
  };

  return (
    <>
      <div
        className="options"
        onClick={() => {
          dispatch(setPopupChildren(<MyProfileModal />));
          dispatch(togglePopup(true));
        }}
      >
        <UserProfileIcon className="profile-icon" />
        <span>My profile</span>
      </div>
      <div
        className="options"
        onClick={() => {
          dispatch(setPopupChildren(<ManagePasswordModal />));
          dispatch(togglePopup(true));
        }}
      >
        <LockIcon className="profile-icon" />
        <span>Manage Password</span>
      </div>
      <div className="options" onClick={handleLogout}>
        <LogoutIcon className="profile-icon" />
        <span>Logout</span>
      </div>
    </>
  );
}
