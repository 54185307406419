import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PopupModal from "../components/PopupModal";
import {
  setActiveLgasOfFocusedState,
  setActiveStates,
  setIndicators,
  setLgInFocus,
  setSelectedLevel,
  setStateInFocus,
  setStates,
} from "../redux/actions/global.action";
import { setMyData } from "../redux/actions/user.action";
import globalService from "../services/global.service";
import { getToken, getUser, removeUserSession } from "../utils/helpers";
import TopBar from "./components/TopBar";
import "./Layout.css";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const token = getToken();
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const user = getUser();
  const { myData } = useSelector((state) => state.user);
  useEffect(() => {
    if (token) {
      // getIndicators();
      getStates();
      getActiveStates();

      dispatch(setMyData(user));
    } else {
      removeUserSession();
      navigate("/login");
    }
  }, [token]);

  const getIndicators = () => {
    globalService
      .getIndicators()
      .then((data) => {
        if (data.message) {
          dispatch(setIndicators(data.message));
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getStates = () => {
    globalService
      .getStates()
      .then((data) => {
        if (data.message) {
          dispatch(
            setStates(
              data.message?.map((state) => ({
                ...state,
                value: state.dh_id,
                label: state.dh_state,
              }))
            )
          );
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getActiveStates = () => {
    globalService
      .getActiveStates()
      .then((data) => {
        if (data.message) {
          dispatch(
            setActiveStates(
              data.message?.map((state) => ({
                ...state,
                value: state.dh_id,
                label: state.dh_state,
              }))
            )
          );

          if (
            user?.roles?.includes("VacNationalAdmin") ||
            user?.email == "national.vacinsight@sydani.org"
          ) {
            dispatch(setSelectedLevel("national"));
          }
          if (user?.roles?.includes("VacStateAdmin")) {
            const state = user?.roles.find((item) =>
              data.message?.some((obj) => obj.dh_state === item)
            );
            const stateObj = data.message?.filter(
              (obj) => obj.dh_state == state
            )[0];

            dispatch(setSelectedLevel("state"));
            dispatch(
              setStateInFocus({
                ...stateObj,
                value: stateObj.dh_id,
                label: stateObj.dh_state,
              })
            );
            getLgasOfFocusedState(stateObj.dh_id);
          }
          if (user?.roles?.includes("VacLgaAdmin")) {
            const state = user?.roles.find((item) =>
              data.message?.some((obj) => obj.dh_state === item)
            );
            const stateObj = data.message?.filter(
              (obj) => obj.dh_state == state
            )[0];
            dispatch(setSelectedLevel("lga"));
            dispatch(
              setStateInFocus({
                ...stateObj,
                value: stateObj.dh_id,
                label: stateObj.dh_state,
              })
            );
            getLgasOfFocusedState(stateObj.dh_id);
          }
        }
      })
      .then((err) => console.log("errrr", err));
  };

  const getLgasOfFocusedState = (stateId) => {
    globalService
      .getActiveLgasInState(stateId)
      .then((data) => {
        dispatch(
          setActiveLgasOfFocusedState(
            data.message?.map((lg) => ({
              ...lg,
              label: lg?.dh_lga,
              value: lg?.dh_id,
            }))
          )
        );

        if (user?.roles?.includes("VacLgaAdmin")) {
          const lga = user?.roles.find((item) =>
            data.message?.some((obj) => obj.dh_lga === item)
          );
          const lgaObj = data.message?.filter((obj) => obj.dh_lga == lga)[0];
          dispatch(setSelectedLevel("lga"));
          dispatch(
            setLgInFocus({
              ...lgaObj,
              value: lgaObj.dh_id,
              label: lgaObj.dh_lga,
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  return (
    <div className="layout-root">
      <TopBar />
      <PopupModal />
      <main className="layout-main-section">{children}</main>
      {page !== "program_areas" && (
        <div className="layout-footer-section">
          <div className="layout-footer-text">
            &#169; {new Date().getFullYear()}{" "}
            <a
              href="https://www.makingbetterfutures.org/"
              target="_"
              className="buttom-links"
            >
              Vaccine Data CoLab
            </a>{" "}
            and{" "}
            <a href="https://sydani.org/" target="_" className="buttom-links">
              Sydani Initiative
            </a>
            .
          </div>

          <div className="layout-footer-text">
            Built by{" "}
            <a
              href="https://heimdallinc.io/"
              target="_"
              className="buttom-links"
            >
              Heimdall
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
