import { makeStyles } from "@mui/styles";

export const AboutStyle = makeStyles((theme) => ({
  aboutRootContainer: {
    backgroundColor: "#284286",
    height: "100%",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: 20,
    textAlign: "center",
  },
  title: {
    fontFamily: "Means !important",
    fontSize: "32px !important",
    color: "#fff",
    fontWeight: "600 !important",
  },
  paragraphs: {
    color: "#fff",
    padding: "0px 50px",
    fontSize: "22px !important",
    lineHeight: 1.7,
  },
}));
