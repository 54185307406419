import React, { useState } from "react";
import "./DownloadModal.css";
import { ReactComponent as PdfIcon } from "../../../../../../assets/images/pdf.svg";
import { ReactComponent as CsvIcon } from "../../../../../../assets/images/csv.svg";
import { IconButton } from "@mui/material";
import { usePDF as useP } from "@react-pdf/renderer";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import InsightRecommendationPdf from "../InsightRecommendationPdf";
import { Margin, usePDF } from "react-to-pdf";
const DownloadModal = ({
  gettingChartData,
  firstComparable,
  secondComparable,
  thirdComparable,
  selectedElements,
  toPdf,
}) => {
  const [instance, updateInstance] = useP({
    document: (
      <InsightRecommendationPdf
        gettingChartData={gettingChartData}
        firstComparable={firstComparable}
        secondComparable={secondComparable}
        thirdComparable={thirdComparable}
        selectedElements={selectedElements}
      />
    ),
  });
  const pdfFileName =
    "vac-insight-recommendation-" +
    new Date().toISOString().replace(/:/g, "-") +
    ".pdf";
  const { toPDF, targetRef } = usePDF({
    filename:
      "vac-insight-chart-" +
      new Date().toISOString().replace(/:/g, "-") +
      ".pdf",
    page: { margin: Margin.MEDIUM },
  });

  const handleDownloadCsv = () => {
    toast.info("The csv file is currently unavailable. Coming Soon");
    console.log(
      firstComparable?.stateCategoryResult?.filter((item) => {
        if (selectedElements?.length) {
          if (selectedElements?.includes(item?.element)) {
            return item;
          }
        } else {
          return item;
        }
      })
    );
    // // flatten object like this {id: 1, title:'', category: ''};
    // const rows = [{ id: 1, title: "title00", category: "categgg" }];

    // // create workbook and worksheet
    // const workbook = XLSX.utils.book_new();
    // const worksheet = XLSX.utils.json_to_sheet(rows);

    // XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // // customize header names
    // XLSX.utils.sheet_add_aoa(worksheet, [
    //   ["Product ID", "Product Name", "Product Category"],
    // ]);

    // XLSX.writeFile(workbook, "ReportFor2023.xlsx", { compression: true });
  };

  return (
    <div className="download-modal-container">
      <div
        style={{
          textAlign: "center",
          fontWeight: 600,
          fontSize: 18,
          marginBlock: 10,
        }}
      >
        Download PDF
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          color: "#011b60",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <IconButton onClick={handleDownloadCsv} className="icons-container">
          <CsvIcon className="csv-pdf-icon" />
        </IconButton> */}
        <a href={instance.url} download={pdfFileName} onClick={toPdf}>
          <IconButton className="icons-container">
            <PdfIcon className="csv-pdf-icon" />
          </IconButton>{" "}
        </a>
      </div>
    </div>
  );
};

export default DownloadModal;
