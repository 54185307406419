export const steps = [
  {
    id: 1,
    name: "Program Areas",
    value: "program_areas",
    route: "/program_areas",
  },
  {
    id: 2,
    name: "Location",
    value: "location",
    route: "/program_areas/location",
  },
  {
    id: 3,
    name: "Period",
    value: "period",
    route: "/program_areas/period",
  },
  {
    id: 4,
    name: "Data summary",
    value: "data_summary",
    route: "/program_areas/summary",
  },
];
