import { RadioButtonChecked } from "@mui/icons-material";
import { StepButton, Stepper, Step } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import globalService from "../../services/global.service";
import BaseComponent from "./components/BaseComponent";
import Location from "./components/Location";
import Period from "./components/Period";
import Summary from "./components/Summary";
import { steps } from "./constants";
import useProgramAreas from "./hooks/useProgramAreas";
import "./ProgramAreas.css";
const ProgramAreas = () => {
 
const {activeStep,handleStep,completed,renderTab,handleNext} = useProgramAreas()

  return (
    <div className="page-wrapper-root">
      <Stepper className="steps-container" nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step
            style={{ color: activeStep == index ? "#FFC60C" : "#fff" }}
            key={step.id}
          >
            <StepButton
              onClick={handleStep(index)}
              completed={completed[index]}
              icon={<RadioButtonChecked />}
            >
              <div
                style={{
                  color: activeStep == index ? "#FFC60C" : "#fff",
                  ...(activeStep == index && { fontWeight: "600" }),
                }}
              >
                {step.name}
              </div>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className="contents-container">{renderTab(activeStep)}</div>
      {activeStep != 3 && (
        <div className="page-footer">
          <button onClick={handleNext} className="continue-btn">
            Update and Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default ProgramAreas;
