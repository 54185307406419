import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Layout from "./layout/Layout";
import Error404Page from "./pages/Error404Page";
import { createTheme, ThemeProvider } from "@mui/material";
import About from "./pages/About";
import ProgramAreas from "./pages/ProgramAreas";
import Login from "./pages/Login/Login";
import { getUserLogin } from "./utils/helpers";
import FaqAndHelp from "./pages/FaqAndHelp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Avenir",
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer position="top-center" />
        <Router>
          <Routes>
            <Route exact path="/login" element={<Login />} />

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about" element={<About />} />
              <Route
                element={
                  <ProgramAreas>
                    <Outlet />
                  </ProgramAreas>
                }
              >
                <Route path="/program_areas" element={<ProgramAreas />} />
              </Route>
              <Route path="/faqs_support" element={<FaqAndHelp />} />
            </Route>

            <Route path="*" element={<Error404Page />} />

          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
