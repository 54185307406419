const APP_PORT = "3000";
const isLive = true;
const apk = false;

const env = {
  environment: apk ? "apk" : process.env.NODE_ENV,

  production: {
    API_URL: "https://vacinsight.sydani.org/api/method",
  },

  development: {
    API_URL: "https://vacinsight.sydani.org/api/method",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ENVIRONMENT: env.environment,
  ...(env.environment === "production" ? env.production : env.development),
};
