import React from "react";
import "./Location.css";
import Select from "react-select";
import {
  setSecondComparable,
  setThirdComparable,
} from "../../../../redux/actions/global.action";
import { AddCircle, HighlightOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ReactComponent as EmptyIndicatorIcon } from "../../../../assets/images/EmptyFileIndicator.svg";
import useLocationHook from "./hooks/useLocationHook";
const Location = () => {
  const {
    firstComparable,
    secondComparable,
    thirdComparable,
    myData,
    states,
    handleSelectState,
    handleSelectLga,
    handleChangeAction,
    handleSelectFacility,
    handleCancelSection,
    dispatch,
    firstComparableRef,
    secondComparableRef,
    thirdComparableRef,
    stateInFocus,
    lgInFocus,
  } = useLocationHook();

  return (
    <div className="location-root-container">
      <div className="inner-root-container">
        <div className="header-text-container">
          {lgInFocus || stateInFocus ? (
            <p className="header-text">
              Kindly select the action you would like to take in{" "}
              {lgInFocus?.dh_lga || stateInFocus?.dh_state}{" "}
            </p>
          ) : (
            <p className="header-text">Kindly select specific location</p>
          )}
        </div>
        <div className="locations-selections-wrapper">
          {firstComparable && (
            <div
              class="category-container"
              style={{
                height: `calc(100vh - 250px)`,
                overflow: "auto",
                scrollBehavior: "smooth",
              }}
              ref={firstComparableRef}
            >
              {!stateInFocus && (
                <div className="selection-container">
                  <p className="select-label">Select State</p>
                  <Select
                    className="select-dropdown"
                    options={firstComparable?.states}
                    isClearable={stateInFocus?.dh_state ? false : true}
                    value={firstComparable.state}
                    onChange={(value) => handleSelectState("first", value)}
                  />
                </div>
              )}

              {firstComparable.state &&
                (firstComparable?.stateAction?.value == "select_lga" ? (
                  <>
                    {!lgInFocus && (
                      <div
                        className="selection-container"
                        style={{ marginTop: 30 }}
                      >
                        <p className="select-label">Select LGA</p>
                        <Select
                          value={firstComparable.lga}
                          className="select-dropdown"
                          options={firstComparable.lgas}
                          isClearable={lgInFocus?.dh_lga ? false : true}
                          onChange={(value) => handleSelectLga("first", value)}
                        />
                      </div>
                    )}

                    {firstComparable.lga && (
                      <div
                        className="selection-container"
                        style={{ marginTop: 30 }}
                      >
                        <p className="select-label">Action:</p>
                        <Select
                          className="select-dropdown"
                          options={firstComparable?.lgaActions}
                          value={firstComparable?.lgaAction}
                          onChange={(value) =>
                            handleChangeAction("first", value)
                          }
                        />
                      </div>
                    )}
                  </>
                ) : !lgInFocus ? (
                  <div
                    className="selection-container"
                    style={{ marginTop: 30 }}
                  >
                    <p className="select-label">Action:</p>
                    <Select
                      className="select-dropdown"
                      options={firstComparable?.stateActions}
                      value={firstComparable?.stateAction}
                      onChange={(value) => handleChangeAction("first", value)}
                    />
                  </div>
                ) : firstComparable.lga ? (
                  <div
                    className="selection-container"
                    style={{ marginTop: 30 }}
                  >
                    <p className="select-label">Action:</p>
                    <Select
                      className="select-dropdown"
                      options={firstComparable?.lgaActions}
                      value={firstComparable?.lgaAction}
                      onChange={(value) => handleChangeAction("first", value)}
                    />
                  </div>
                ) : null)}

              {firstComparable.lgaAction?.value === "lga_facilities" && (
                <div className="facilities-section-container">
                  <p className="facilities-head-text">HEALTH FACILITIES</p>
                  {firstComparable.facilities?.length ? (
                    <div className="facilities-container">
                      {firstComparable.facilities?.map((facility, index) => (
                        <div
                          onClick={() =>
                            handleSelectFacility("first", facility)
                          }
                          className="facility-container"
                          key={index}
                        >
                          <input
                            onChange={() =>
                              handleSelectFacility("first", facility)
                            }
                            className="checkbox"
                            type="checkbox"
                            checked={firstComparable?.selectedFacilities
                              ?.map((item) => item?.dh_id)
                              .includes(facility.dh_id)}
                          />
                          <p className="facility-text">
                            {facility?.dh_facility}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="empty-facility-container">
                      <EmptyIndicatorIcon
                        style={{ width: 100, height: 100, marginBottom: 10 }}
                      />
                      <p>No facility Found</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {secondComparable?.shouldShow ? (
            <div
              class="category-container"
              style={{
                height: `calc(100vh - 250px)`,
                overflow: "auto",
                scrollBehavior: "smooth",
              }}
              ref={secondComparableRef}
            >
              <div style={{ width: "100%", position: "relative", zIndex: 5 }}>
                <IconButton
                  onClick={() => handleCancelSection("second")}
                  className="cancel-selection-btn"
                >
                  <HighlightOff color="red" />
                </IconButton>
              </div>
              {!stateInFocus && (
                <div className="selection-container">
                  <p className="select-label">Select State</p>
                  <Select
                    className="select-dropdown"
                    options={secondComparable?.states}
                    isClearable={stateInFocus?.dh_state ? false : true}
                    value={secondComparable.state}
                    onChange={(value) => handleSelectState("second", value)}
                  />
                </div>
              )}

              {secondComparable.state &&
                (secondComparable?.stateAction?.value == "select_lga" ? (
                  <>
                    {!lgInFocus && (
                      <div
                        className="selection-container"
                        style={{ marginTop: 30 }}
                      >
                        <p className="select-label">Select LGA</p>
                        <Select
                          value={secondComparable.lga}
                          className="select-dropdown"
                          options={secondComparable.lgas}
                          isClearable={lgInFocus?.dh_lga ? false : true}
                          onChange={(value) => handleSelectLga("second", value)}
                        />
                      </div>
                    )}

                    {secondComparable.lga && (
                      <div
                        className="selection-container"
                        style={{ marginTop: 30 }}
                      >
                        <p className="select-label">Action:</p>
                        <Select
                          className="select-dropdown"
                          options={secondComparable?.lgaActions}
                          value={secondComparable?.lgaAction}
                          onChange={(value) =>
                            handleChangeAction("second", value)
                          }
                        />
                      </div>
                    )}
                  </>
                ) : !lgInFocus ? (
                  <div
                    className="selection-container"
                    style={{ marginTop: 30 }}
                  >
                    <p className="select-label">Action:</p>
                    <Select
                      className="select-dropdown"
                      options={secondComparable?.stateActions}
                      value={secondComparable?.stateAction}
                      onChange={(value) => handleChangeAction("second", value)}
                    />
                  </div>
                ) : secondComparable.lga ? (
                  <div
                    className="selection-container"
                    style={{ marginTop: 30 }}
                  >
                    <p className="select-label">Action:</p>
                    <Select
                      className="select-dropdown"
                      options={secondComparable?.lgaActions}
                      value={secondComparable?.lgaAction}
                      onChange={(value) => handleChangeAction("second", value)}
                    />
                  </div>
                ) : null)}

              {secondComparable.lgaAction?.value === "lga_facilities" && (
                <div className="facilities-section-container">
                  <p className="facilities-head-text">HEALTH FACILITIES</p>
                  {secondComparable.facilities?.length ? (
                    <div className="facilities-container">
                      {secondComparable.facilities?.map((facility, index) => (
                        <div
                          onClick={() =>
                            handleSelectFacility("second", facility)
                          }
                          className="facility-container"
                          key={index}
                        >
                          <input
                            onChange={() =>
                              handleSelectFacility("second", facility)
                            }
                            className="checkbox"
                            type="checkbox"
                            checked={secondComparable?.selectedFacilities
                              ?.map((item) => item?.dh_id)
                              .includes(facility.dh_id)}
                          />
                          <p className="facility-text">
                            {facility?.dh_facility}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="empty-facility-container">
                      <EmptyIndicatorIcon
                        style={{ width: 100, height: 100, marginBottom: 10 }}
                      />
                      <p>No facility Found</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div class="empty-category-container">
              <div
                onClick={() =>
                  dispatch(
                    setSecondComparable({
                      ...secondComparable,
                      shouldShow: true,
                    })
                  )
                }
                className="compare-var-container"
              >
                <AddCircle className="compare-var-icon" />
                <p className="compare-var-text">Compare Data</p>
              </div>
            </div>
          )}
          {thirdComparable?.shouldShow ? (
            <div
              class="category-container"
              style={{
                height: `calc(100vh - 250px)`,
                overflow: "auto",
                scrollBehavior: "smooth",
              }}
              ref={thirdComparableRef}
            >
              <div style={{ width: "100%", position: "relative" }}>
                <IconButton
                  onClick={() => handleCancelSection("third")}
                  className="cancel-selection-btn"
                >
                  <HighlightOff color="red" />
                </IconButton>
              </div>
              {!stateInFocus && (
                <div className="selection-container">
                  <p className="select-label">Select State</p>
                  <Select
                    className="select-dropdown"
                    options={thirdComparable?.states}
                    isClearable={stateInFocus?.dh_state ? false : true}
                    value={thirdComparable.state}
                    onChange={(value) => handleSelectState("third", value)}
                  />
                </div>
              )}

              {thirdComparable.state &&
                (thirdComparable?.stateAction?.value == "select_lga" ? (
                  <>
                    {!lgInFocus && (
                      <div
                        className="selection-container"
                        style={{ marginTop: 30 }}
                      >
                        <p className="select-label">Select LGA</p>
                        <Select
                          value={thirdComparable.lga}
                          className="select-dropdown"
                          options={thirdComparable.lgas}
                          isClearable={lgInFocus?.dh_lga ? false : true}
                          onChange={(value) => handleSelectLga("third", value)}
                        />
                      </div>
                    )}

                    {thirdComparable.lga && (
                      <div
                        className="selection-container"
                        style={{ marginTop: 30 }}
                      >
                        <p className="select-label">Action:</p>
                        <Select
                          className="select-dropdown"
                          options={thirdComparable?.lgaActions}
                          value={thirdComparable?.lgaAction}
                          onChange={(value) =>
                            handleChangeAction("third", value)
                          }
                        />
                      </div>
                    )}
                  </>
                ) : !lgInFocus ? (
                  <div
                    className="selection-container"
                    style={{ marginTop: 30 }}
                  >
                    <p className="select-label">Action:</p>
                    <Select
                      className="select-dropdown"
                      options={thirdComparable?.stateActions}
                      value={thirdComparable?.stateAction}
                      onChange={(value) => handleChangeAction("third", value)}
                    />
                  </div>
                ) : thirdComparable.lga ? (
                  <div
                    className="selection-container"
                    style={{ marginTop: 30 }}
                  >
                    <p className="select-label">Action:</p>
                    <Select
                      className="select-dropdown"
                      options={thirdComparable?.lgaActions}
                      value={thirdComparable?.lgaAction}
                      onChange={(value) => handleChangeAction("third", value)}
                    />
                  </div>
                ) : null)}

              {thirdComparable.lgaAction?.value === "lga_facilities" && (
                <div className="facilities-section-container">
                  <p className="facilities-head-text">HEALTH FACILITIES</p>
                  {thirdComparable.facilities?.length ? (
                    <div className="facilities-container">
                      {thirdComparable.facilities?.map((facility, index) => (
                        <div
                          onClick={() =>
                            handleSelectFacility("third", facility)
                          }
                          className="facility-container"
                          key={index}
                        >
                          <input
                            onChange={() =>
                              handleSelectFacility("third", facility)
                            }
                            className="checkbox"
                            type="checkbox"
                            checked={thirdComparable?.selectedFacilities
                              ?.map((item) => item?.dh_id)
                              .includes(facility.dh_id)}
                          />
                          <p className="facility-text">
                            {facility?.dh_facility}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="empty-facility-container">
                      <EmptyIndicatorIcon
                        style={{ width: 100, height: 100, marginBottom: 10 }}
                      />
                      <p>No facility Found</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div class="empty-category-container">
              {secondComparable?.shouldShow && (
                <div
                  onClick={() =>
                    dispatch(
                      setThirdComparable({
                        ...thirdComparable,
                        shouldShow: true,
                      })
                    )
                  }
                  className="compare-var-container"
                >
                  <AddCircle className="compare-var-icon" />
                  <p className="compare-var-text">Compare Data</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Location;
