import { HighlightOffOutlined } from "@mui/icons-material";
import "./ForgottenPassword.css"
import { useDispatch } from "react-redux";
import { togglePopup } from "../../../../redux/actions/global.action";

export default function ForgottenPassword() {
  const dispatch = useDispatch()
  return <div className="ForgottenPassword_Wrapper">
    <div className="ForgottenPassword_Header">
      <span className="ForgottenPassword_Header_Logo">VacInsights</span>
    </div>
    <form className="ForgottenPassword_body">
      <span className="ForgottenPassword_Header_FormTitle">Forgotten Password</span>
      <div className="ForgottenPassword_TextWrapper">
        <label className="ForgottenPassword_Form_Label" htmlFor="">
          Email Address
        </label>
        <input className="ForgottenPassword_Form_Input" type="email" name="" id="" />
      </div>
      <div className="ForgottenPassword_Footer">
        <button
          onClick={() => {

          }}
          className="ForgottenPassword_LoginBtn"
        >
          Reset Password
        </button>
        <span>Already a have an account? 
          <span onClick={() => {

          }}> Sign in
          </span>
        </span>
      </div>
    </form>
  </div>;
}
