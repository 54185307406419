import {
  SET_CONFIRM_MODAL,
  SET_COUNTRY_IN_FOCUS,
  SET_LG_IN_FOCUS,
  SET_POPUP_CHILDREN,
  SET_SELECTED_FACILITIES,
  SET_PRIMARY_INDICATORS,
  SET_SELECTED_LEVEL,
  SET_SELECTED_LGS,
  SET_SELECTED_PROGRAM_AREA,
  SET_SELECTED_STATES,
  SET_SHOW_POPUP,
  SET_STATE_IN_FOCUS,
  SET_SECONDARY_INDICATORS,
  SET_INDICATORS,
  SET_STATES,
  SET_LGAS_IN_STATE,
  SET_FACILITIES_IN_LGA,
  SET_CHART,
  SET_PERIODS,
  SET_FIRST_COMPARABLE,
  SET_SECOND_COMPARABLE,
  SET_THIRD_COMPARABLE,
  SET_ACTIVE_STATES,
  SET_ACTIVE_LGAS_OF_FOCUSED_STATE,
  SET_GETTING_CHART_DATA,
  SET_SELECTED_CATEGORY,
  SET_CATEGORIES,
  SET_SELECTED_ELEMENTS,
} from "../actionTypes/global.type";

const defaultState = {
  showPopup: false,
  popupChildren: null,
  confirmModal: false,
  onModalDismiss: () => {},
  selectedProgramArea: null,
  selectedLevel: "",
  selectedStates: [],
  selectedLgas: [],
  selectedFacilities: [],
  indicators: [],
  primaryIndicators: [],
  secondaryIndicators: [],
  countryInFocus: "",
  stateInFocus: null,
  lgInFocus: "",
  states: [],
  lgasInState: [],
  facilitiesInLga: [],
  chart: null,
  periods: [],
  firstComparable: {
    shouldShow: true,
    locationType: "summary",
    state: null,
    states: [],
    selectedStates: [],
    lga: null,
    lgas: [],
    selectedLgas: [],
    facilities: [],
    selectedFacilities: [],
    stateAction: {
      label: "View State Summary",
      value: "state_summary",
    },
    lgaAction: {
      label: "View LGA Summary",
      value: "lga_summary",
    },
    stateActions: [
      {
        label: "View State Summary",
        value: "state_summary",
      },
      {
        label: "Select LGA",
        value: "select_lga",
      },
    ],
    lgaActions: [
      {
        label: "View LGA Summary",
        value: "lga_summary",
      },
      {
        label: "View LGA Facilities",
        value: "lga_facilities",
      },
    ],
    showDate: true,
    selectedDate: null,
  },
  secondComparable: {
    shouldShow: false,
    locationType: "summary",
    state: null,
    states: [],
    selectedStates: [],
    lga: null,
    lgas: [],
    selectedLgas: [],
    facilities: [],
    selectedFacilities: [],
    stateAction: {
      label: "View State Summary",
      value: "state_summary",
    },
    lgaAction: {
      label: "View LGA Summary",
      value: "lga_summary",
    },
    stateActions: [
      {
        label: "View State Summary",
        value: "state_summary",
      },
      {
        label: "Select LGA",
        value: "select_lga",
      },
    ],
    lgaActions: [
      {
        label: "View LGA Summary",
        value: "lga_summary",
      },
      {
        label: "View LGA Facilities",
        value: "lga_facilities",
      },
    ],
    showDate: false,
    selectedDate: null,
  },
  thirdComparable: {
    shouldShow: false,
    locationType: "summary",
    state: null,
    states: [],
    selectedStates: [],
    lga: null,
    lgas: [],
    selectedLgas: [],
    facilities: [],
    selectedFacilities: [],
    stateAction: {
      label: "View State Summary",
      value: "state_summary",
    },
    lgaAction: {
      label: "View LGA Summary",
      value: "lga_summary",
    },
    stateActions: [
      {
        label: "View State Summary",
        value: "state_summary",
      },
      {
        label: "Select LGA",
        value: "select_lga",
      },
    ],
    lgaActions: [
      {
        label: "View LGA Summary",
        value: "lga_summary",
      },
      {
        label: "View LGA Facilities",
        value: "lga_facilities",
      },
    ],
    showDate: false,
    selectedDate: null,
  },
  activeStates: [],
  activeLgasOfFocusedState: [],
  gettingChartData: false,
  selectedCategory: null,
  categories: [],
  selectedElements: [],
};

export default function globalReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SHOW_POPUP:
      return { ...state, showPopup: action.payload };
    case SET_POPUP_CHILDREN:
      return {
        ...state,
        popupChildren: action.payload,
        onModalDismiss: action.onDismiss,
      };
    case SET_CONFIRM_MODAL:
      return { ...state, confirmModal: action.payload };
    case SET_SELECTED_PROGRAM_AREA:
      return { ...state, selectedProgramArea: action.payload };
    case SET_SELECTED_LEVEL:
      return { ...state, selectedLevel: action.payload };
    case SET_SELECTED_STATES:
      return { ...state, selectedStates: action.payload };
    case SET_SELECTED_LGS:
      return { ...state, selectedLgas: action.payload };
    case SET_SELECTED_FACILITIES:
      return { ...state, selectedFacilities: action.payload };
    case SET_INDICATORS:
      return { ...state, indicators: action.payload };
    case SET_PRIMARY_INDICATORS:
      return { ...state, primaryIndicators: action.payload };
    case SET_SECONDARY_INDICATORS:
      return { ...state, secondaryIndicators: action.payload };
    case SET_COUNTRY_IN_FOCUS:
      return { ...state, countryInFocus: action.payload };
    case SET_STATE_IN_FOCUS:
      return { ...state, stateInFocus: action.payload };
    case SET_LG_IN_FOCUS:
      return { ...state, lgInFocus: action.payload };
    case SET_STATES:
      return { ...state, states: action.payload };
    case SET_LGAS_IN_STATE:
      return { ...state, lgasInState: action.payload };
    case SET_FACILITIES_IN_LGA:
      return { ...state, facilitiesInLga: action.payload };
    case SET_CHART:
      return { ...state, chart: action.payload };
    case SET_PERIODS:
      return { ...state, periods: action.payload };
    case SET_FIRST_COMPARABLE:
      return { ...state, firstComparable: action.payload };
    case SET_SECOND_COMPARABLE:
      return { ...state, secondComparable: action.payload };
    case SET_THIRD_COMPARABLE:
      return { ...state, thirdComparable: action.payload };
    case SET_ACTIVE_STATES:
      return { ...state, activeStates: action.payload };
    case SET_ACTIVE_LGAS_OF_FOCUSED_STATE:
      return { ...state, activeLgasOfFocusedState: action.payload };
    case SET_GETTING_CHART_DATA:
      return { ...state, gettingChartData: action.payload };
    case SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case SET_CATEGORIES:
      return { ...state, categories: action.payload };
    case SET_SELECTED_ELEMENTS:
      return { ...state, selectedElements: action.payload };
    default:
      return state;
  }
}
