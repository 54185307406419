import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import PropTypes from "prop-types";
import defaultStaticRanges from "./constants";

import "./DateRangeSelector.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstComparable,
  setPeriods,
  setSecondComparable,
  setThirdComparable,
} from "../../redux/actions/global.action";

const DateRangeSelector = ({
  ranges,
  onChange,
  onSubmit,
  section,
  ...rest
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const dispatch = useDispatch();
  const { firstComparable, secondComparable, thirdComparable, periods } =
    useSelector((state) => state.global);
  const [show, setShow] = useState(false);

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "MM/DD/yyyy");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);

    const dateObj = {
      endDate: format(ranges.selection.endDate, "yyyy-MM-dd"),
      startDate: format(ranges.selection.startDate, "yyyy-MM-dd"),
      label: `${format(ranges.selection.startDate, "MMM, yyyy")} - ${format(
        ranges.selection.endDate,
        "MMM, yyyy"
      )}`,
      value: `${format(ranges.selection.startDate, "YYY-MM-dd")},${format(
        ranges.selection.endDate,
        "YYY-MM-dd"
      )}`,
    };

    switch (section) {
      case "first":
        dispatch(
          setFirstComparable({
            ...firstComparable,
            selectedDate: dateObj,
          })
        );
        break;
      case "second":
        dispatch(
          setSecondComparable({
            ...secondComparable,
            selectedDate: dateObj,
          })
        );
        break;
      case "third":
        dispatch(
          setThirdComparable({
            ...thirdComparable,
            selectedDate: dateObj,
          })
        );
        break;
      default:
        break;
    }
    if (!periods.map((period) => period?.section).includes(section)) {
      dispatch(setPeriods([...periods, { ...dateObj, section }]));
    } else {
      const index = periods.findIndex((period) => period.section == section);

      if (index !== -1) {
        dispatch(
          setPeriods(
            periods.map((period, i) =>
              i == index ? { ...dateObj, section } : period
            )
          )
        );
      }
    }
  };

  // const onClickDone = () => {
  //      onSubmit(selectedDateRange);
  //      setShow(true);
  // };

  const onClickClear = () => {
    setSelectedDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setShow(false);
  };

  return (
    <React.Fragment>
      <div className="shadow d-inline-block">
        <DateRangePicker
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[selectedDateRange]}
          direction="horizontal"
          maxDate={new Date()}
          displayMode="dateRange"
          startDatePlaceholder="From"
          endDatePlaceholder="To"
        />
        {/* <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
          <button
            className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
            onClick={() => setShow(true)}
          >
            Done
          </button>
          <button
            className="btn btn-transparent text-danger rounded-0 px-4"
            onClick={onClickClear}
          >
            Clear
          </button>
        </div> */}
      </div>

      {show && (
        <div className="h-100 mt-3 alert alert-transparent">
          <p className="my-auto d-inline">
            Start Date : {formatDateDisplay(selectedDateRange.startDate)}
            {" | "}
            End Date : {formatDateDisplay(selectedDateRange.endDate)}
          </p>
          <button
            className="mb-1 btn btn-transparent text-danger"
            onClick={() => setShow(false)}
            variant="outline-success"
          >
            {" "}
            Close
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

DateRangeSelector.defaultProps = {
  ranges: defaultStaticRanges,
};

DateRangeSelector.propTypes = {
  /**
   * On Submit
   */
  onSubmit: PropTypes.func,
};

export default DateRangeSelector;
